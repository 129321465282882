import * as _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
// import { store } from '../index';
import {
	COA_TYPECODES,
	Comm_Divisor_Dict,
	Comm_Multiplier_Dict,
	Comm_SymbolTo_Volume_Dict,
	COMMODITY_SUMMARY,
	DRP_COMP_ENDORSEMENT,
	DRP_COMP_ENDORSEMENT_SUMMARY,
	FULL_MONTHS,
	IDENTIFIERS,
	JOURNAL_HEADER_KEYS,
	NUM_OF_CARDS,
	RM_COMMODITIES,
	RM_OPERATING_FORECAST_SECTION_CODES,
	RM_OPTIONS,
	RM_SECTION_CODES,
	RM_TYPECODES,
	SCHEMA_TYPE,
	SECTION_CODES,
	SECTION_TYPE_CODE,
	STORED_AMT_KEYS,
	STORED_MEASURE_KEYS,
} from '../../common/constants';
import {
	ContractType,
	Get_ContractTypeFromSubType,
	lblMadCapLastUpdate,
	MADCAP_COMMODITY_NAME_MAP,
	MapStatusCodeToString,
	PositionSourceType,
	TrimSpaceFromMadCapID,
	VolumeInOneContract,
} from '../../common/constants/madcap.constants';
import {
	addMonths,
	cleanupdrpdata,
	createRMSummaryData,
	formatDatewithMMDDYYYY,
	getPropertyIndex,
	parseJSON,
	reducePositionListInfoDictionary,
	stringifyJSON,
	updateObject,
	updateRefrence,
} from '../../common/utils/utility';
import { getsortedpositionList } from '../../containers/rm/steps/position/utility';
import {
	ActionSchema,
	COAInfo,
	DMC,
	DRP,
	DRP_Comp_Quarterly_Endorsement_Summary,
	DRP_Quarterly_Endorsement_Summary,
	GenericSchema,
	MarketPrice,
	PeriodSummary,
	PeriodSummaryForForecast,
	PositionSummary,
	RMHeader,
} from '../../schemas';
import { MadCapPosition } from '../../schemas/madcap.schema';
import {
	NetCeilingMTM,
	NetCollarMTM,
	NetContractMTM,
	NetFloorMTM,
	PositionContainer,
	PositionEditFieldType,
	PositionSummaryModel,
} from '../../schemas/Model/position.model';
import {
	COA_DATA_CHANGED,
	COA_ERROR_SAVE_DATA_TO_DB,
	COA_FETCH_FAIL,
	COA_FETCH_START,
	COA_FETCH_SUCCESS,
	COA_RESET_COP,
	COA_RESET_DATA,
	COA_RESET_RM,
	COA_SAVE_DATA,
	COA_SAVE_DATA_TO_DB,
	COA_SAVE_DATA_TO_DB_START,
	COA_SCHEMA_CALCULATION_CHANGED,
	COA_SCHEMA_MAAPED_WITH_DATA,
	COA_SCHEMA_REMOVE_SELECTED,
	COA_SCHEMA_SELECTED,
	COA_UPDATE_DRP_DATA,
	COA_UPDATE_RESET,
	COA_UPDATE_RM_DATA,
	ENTRY_FORM_ERROR,
	RM_ADD_NEW_POSITION,
	RM_MOVE_POSITION_DICTIONARY_DATA,
	RM_POSITION_CALCULATE_ALL,
	RM_POSITION_DATA_FROM_MADCAP,
	RM_POSITION_DATA_UPDATE,
	RM_POSITION_DELETE,
	RM_POSITION_HEADER_UPDATE,
	RM_POSITION_RECALCULATE_MONTH_YEAR,
	RM_POSITION_UPDATE_MARKETPRICE,
	RM_POSTION_DATA_COLLAR_COUNT,
	RM_UPDATEVALIDATION_POSITION,
} from '../actions/actions';
import { PositionModel } from './../../schemas/Model/position.model';
import { Position_DICTIONARY } from './../actions/actions';

const formatDatetimecomingfromapi = (apiItem: any, datestring: any) => {
	let dstr = formatDatewithMMDDYYYY(new Date(datestring));
	return dstr;
};

const initialState: COAInfo = {
	mappedSchema: null,
	calculatedSchema: null,
	isSyncedToDB: false,
	loading: true,
	isDataModified: false,
	error: new Error(),
	formError: null,
	reset: false,
};

const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case COA_FETCH_START:
			return updateObject(state, {
				loading: true,
			});
		case COA_FETCH_FAIL:
			return updateObject(state, {
				error: action.payload.error,
				loading: false,
			});
		case COA_FETCH_SUCCESS:
			return updateObject(state, {
				loading: false,
			});
		case COA_SCHEMA_SELECTED:
			return updateObject(state, {
				mappedSchema: updateRefrence(formatSchema(action.payload)),
				loading: true,
				isSyncedToDB: false,
				isDataModified: false,
				error: null,
				formError: null,
			});
		case COA_SCHEMA_REMOVE_SELECTED:
			return Object.assign({}, state, {
				mappedSchema: null,
				calculatedSchema: null,
				loading: false,
				isSyncedToDB: false,
				isDataModified: false,
				error: null,
				formError: null,
			});
		case COA_SAVE_DATA:
			return updateObject(state, {
				isDataModified: true,
				mappedSchema: action.payload,
				loading: false,
			});
		case COA_RESET_DATA:
			return updateObject(state, {
				isDataModified: true,
				calculatedSchema: updateRefrence(state.mappedSchema),

				loading: false,
				formError: null,
				reset: false,
			});
		case COA_UPDATE_RESET:
			return updateObject(state, {
				reset: action.payload,
				calculatedSchema: updateRefrence(state.mappedSchema),
			});
		case COA_SAVE_DATA_TO_DB_START:
			return updateObject(state, {
				loading: true,
			});
		case COA_SAVE_DATA_TO_DB:
			return updateObject(state, {
				isDataModified: false,
				isSyncedToDB: true,
				loading: false,
				formError: null,
			});
		case COA_ERROR_SAVE_DATA_TO_DB:
			return updateObject(state, {
				isDataModified: true,
				isSyncedToDB: false,
				loading: false,
				error: action.payload.error,
			});
		case COA_DATA_CHANGED:
			return updateObject(state, {
				isDataModified: action.payload,
				loading: false,
			});
		case COA_SCHEMA_MAAPED_WITH_DATA:
			return updateObject(state, {
				mappedSchema: formatSchema(
					action.payload.schema,
					action.payload.basicInfo,
					action.payload.prevYearJournalEntryLine
				),
				loading: false,
				formError: null,
			});
		case ENTRY_FORM_ERROR:
			return updateObject(state, {
				formError: action.payload,
			});
		case COA_SCHEMA_CALCULATION_CHANGED:
			return updateCalculation(state, action);
		case COA_RESET_RM:
			return resetRM(state, action);
		case COA_RESET_COP:
			return resetCOP(state, action);
		case RM_POSITION_HEADER_UPDATE:
			state.calculatedSchema.Header = updateHeaderWithDate(
				_.cloneDeep(state.calculatedSchema.Header)
			);
			return state;
		case RM_POSITION_DATA_FROM_MADCAP:
			if (
				action.payload.externaldrpdata &&
				action.payload.externaldrpdata.length > 0
			) {
				updateDRPdataWithExternalDrpdata(
					action.payload.externaldrpdata,
					state,
					action.payload.financialYear
				);
			}
			if (
				action.payload.listContracts &&
				action.payload.listContracts.length > 0
			) {
				updatePostionsDataWithMadCapData(
					action.payload.listContracts,
					state,
					action.payload.financialYear
				);
			}
			return state;
		case RM_POSITION_DATA_UPDATE:
			return updatePositionDataWithPos(state, action.payload);
		case RM_POSTION_DATA_COLLAR_COUNT:
			return updateNoofContractAndVolumeForCollar(state, action.payload);
		case RM_POSITION_UPDATE_MARKETPRICE:
			return updatePostionWithMarketPrice(state, action.payload);
		case RM_POSITION_RECALCULATE_MONTH_YEAR:
			return calculateAllThePositionsForMonthAndYear(state, action.payload);
		case RM_POSITION_DELETE:
			return deletePositionFromPC(state, action.payload);
		case RM_POSITION_CALCULATE_ALL:
			return calculateAllPositions(state, action.payload);
		case RM_ADD_NEW_POSITION:
			return addPositionOfType(state, action.payload);
		case RM_MOVE_POSITION_DICTIONARY_DATA:
			// commenting it as app become re-render loops on changing it
			return moveRMPostionDictionaryData(state);
		case RM_UPDATEVALIDATION_POSITION:
			updateRMPositionvalidation(state);
			return updateObject(state, {
				isDataModified: true,
			});
		case COA_UPDATE_DRP_DATA:
			return {
				...state,
				calculatedSchema: {
					...state.calculatedSchema,
					[RM_SECTION_CODES.DRP_COMP]: action.payload, // Update with new data
				},
			};
		case COA_UPDATE_RM_DATA:
			// Logic to update RM_DATA in the calculatedSchema
			return {
				...state,
				calculatedSchema: {
					...state.calculatedSchema,
					[RM_SECTION_CODES.DRP]: action.payload, // Update with new data
				},
			};
		// case SET_DRP_ERROR:
		// 	return {
		// 		...state,
		// 		drpError: action.payload,
		// 	};
		default:
			return state;
	}
};

/*DRP Mapping External Source data to COA Schema code Start */

// Function to convert API item to EndorsementModel format
const mapDRPApiResponseToDRPCOMPONENTEndorsementModel = (apiItem: any) => {
	const dtprotein = apiItem.declaredTestProtein
		? new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })
				.format(apiItem.declaredTestProtein)
				.toString()
		: '3.05';

	const dtnonflatsolid = 5.7 + parseFloat(dtprotein);

	const yieldadjustmentfactor = apiItem.yieldAdjustment
		? apiItem.yieldAdjustment
		: '0.000';

	const premiumdollar = apiItem.premium ? apiItem.premium : 0;
	const declaredprod = apiItem.declaredProduction
		? apiItem.declaredProduction
		: 0;

	return {
		typeCode: 'Endorsement',
		identifierSet: {
			identifier: '3', // Assuming this is constant or you can derive it from the API response
		},
		dRPReferences: [
			{
				typeCode: 'DRP Reference',
				identifier: apiItem.drpReference || '',
			},
			{
				typeCode: 'DRP Source',
				identifier: 'System',
			},
			{
				typeCode: 'DRP Note',
				identifier: apiItem.note || '', // You may need to derive this based on other conditions
			},
		],
		quantity: [
			{
				content: declaredprod,
				typeCode: DRP_COMP_ENDORSEMENT.Declared_Production_QTR,
			},
			{
				content: apiItem.qtrlyMilkPriceButterfat?.toString() || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Butterfat,
			},
			{
				content: apiItem.qtrlyMilkPriceProtein?.toString() || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Protein,
			},
			{
				content: apiItem.qtrlyMilkPriceOtherSolids?.toString() || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Other_Solids,
			},
			{
				content: apiItem.qtrlyMilkPriceNonfatSolids?.toString() || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Non_Fat_Solids,
			},
			{
				content: apiItem.declaredTestButterfat
					? new Intl.NumberFormat('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
					  })
							.format(apiItem.declaredTestButterfat)
							.toString()
					: '3.70',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Butterfat,
			},
			{
				content: dtprotein,
				typeCode: DRP_COMP_ENDORSEMENT.DT_Protein,
			},
			{
				content: '5.70',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Other_Solids,
			},
			{
				content: dtnonflatsolid,
				typeCode: DRP_COMP_ENDORSEMENT.DT_Non_Fat_Solids,
			},
			{
				content: apiItem.quoteButterfatPrice || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Butterfat,
			},
			{
				content: apiItem.quoteProteinPrice || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Protein,
			},
			{
				content: apiItem.quoteOtherSolidsPrice || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Other_Solids,
			},
			{
				content: apiItem.quoteNonfatSolidsPrice || '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Non_Fat_Solids,
			},
			{
				content: '100',
				typeCode: DRP_COMP_ENDORSEMENT.Comp_Weight_factor,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.NonFt_BF_Wght,
			},
			{
				content: apiItem.protectionFactor
					? new Intl.NumberFormat('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
					  })
							.format(apiItem.protectionFactor)
							.toString()
					: '1.40',
				typeCode: DRP_COMP_ENDORSEMENT.Protection_Factor,
			},
			{
				content: apiItem.covered
					? parseInt((apiItem.covered * 100).toString()).toString()
					: '95',
				typeCode: DRP_COMP_ENDORSEMENT.Percentage_Covered,
			},
			{
				content: yieldadjustmentfactor,
				typeCode: DRP_COMP_ENDORSEMENT.Yield_Adj_Fact,
			},
			{
				content: premiumdollar,
				typeCode: DRP_COMP_ENDORSEMENT.Premium_Paid_Total,
			},
		],
		extension: {
			typeCode: 'Endorsement Summary',
			amount: [
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Total_Price_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Expected_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Beg_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Market_Price_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Prob_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.MTM_Adj_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Expected_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Beg_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Market_Price_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Prob_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.MTM_Adj_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Net_MTM_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Net_MTM_TOTAL,
				},
			],
			code: [], // Assuming there are no corresponding fields for this in API response
			datetime: formatDatetimecomingfromapi(apiItem, apiItem.endrosementDate),
		},
	};
};

const mapDRPApiResponseToEndorsementModel = (apiItem: any) => {
	const class3weight = apiItem.class3Weight
		? apiItem.class3Weight * 100 + ''
		: '0';
	const class4weight = 100 - parseInt(class3weight) + '';

	const premiumdollar = apiItem.premium ? apiItem.premium : '0';

	const declaredprod = apiItem.declaredProduction
		? apiItem.declaredProduction
		: '0';

	const mappeddata = {
		typeCode: RM_TYPECODES.ENDORSEMENT,
		identifierSet: {
			identifier: '3', // Assuming static identifier as in the example model
		},
		dRPReferences: [
			{
				typeCode: 'DRP Reference',
				identifier: apiItem.drpReference || '',
			},
			{
				typeCode: 'DRP Source',
				identifier: 'System',
			},
			{
				typeCode: 'DRP Note',
				identifier: apiItem.note || '',
			},
		],
		quantity: [
			{
				content: declaredprod,
				typeCode: RM_TYPECODES.DECLARED_PROD_QTR_END,
			},
			{
				content: apiItem.class_3_Floor_Price || '0.00',
				typeCode: RM_TYPECODES.CLASS_3_FLOOR_PRICE,
			},
			{
				content: apiItem.class_4_Floor_Price || '0.00',
				typeCode: RM_TYPECODES.CLASS_4_FLOOR_PRICE,
			},
			{
				content: apiItem.covered
					? parseInt((apiItem.covered * 100).toString()).toString()
					: '0',
				typeCode: RM_TYPECODES.PERCENT_COVERED,
			},
			{
				content: apiItem.protectionFactor
					? new Intl.NumberFormat('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
					  })
							.format(apiItem.protectionFactor)
							.toString()
					: '0',
				typeCode: RM_TYPECODES.PROTECT_FACT,
			},
			{
				content: premiumdollar,
				typeCode: RM_TYPECODES.PREMIUM_DOLLAR,
			},
			{
				content: apiItem.yieldAdjustment ? apiItem.yieldAdjustment : '1.0000',
				typeCode: RM_TYPECODES.YIELD_ADJ,
			},
			{
				content: class3weight,
				typeCode: RM_TYPECODES.CLASS3_WEIGHT_FACTOR,
			},
			{
				content: class4weight,
				typeCode: RM_TYPECODES.CLASS4_WEIGHT_FACTOR,
			},
			{
				content: '0', // Assuming no data provided, placeholder
				typeCode: RM_TYPECODES.PREMIUM_PAID,
			},
		],
		extension: {
			typeCode: 'Endorsement Summary',
			amount: [
				{
					content: '0', // Assuming placeholders; replace with real data if available
					typeCode: RM_TYPECODES.GROSS_EXPECTED_REVENUE_GUARANTEE_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_WEIGHTED_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_PROBABLE_INDEMNITY_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_WYF_ADJUSTMENT_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEG_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROS_PROB_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_ADJ_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_TO_MARKET_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.NET_MTM_TOTAL,
				},
			],
			code: [],
			datetime: formatDatetimecomingfromapi(apiItem, apiItem.endrosementDate),
		},
	};
	return mappeddata;
};

const updatedrpclassendorsementandsummary = (
	externaldrpdata: Array<any>,
	DRPDATA: any,
	state: any,
	year: number
) => {
	const externaldrpclassdata = externaldrpdata.filter((data: any) => {
		return data.endorsementType === '831';
	});
	const tempdrpdata: any = [];
	if (externaldrpclassdata.length > 0) {
		DRPDATA.forEach((drpclassdataitem: any) => {
			const distribution = drpclassdataitem?.extension?.distribution || [];
			let filteredDistribution: any = [];
			distribution.forEach((distItem: any) => {
				distItem.quantity = distItem.quantity.filter(
					(d: any) => d.typeCode != ''
				);

				if (distItem.typeCode === 'Endorsement') {
					const drpReferencesarray = distItem?.dRPReferences || [];
					const drpReference = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Reference'
					);
					const drpSource = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Source'
					);
					const drpNote = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Note'
					);
					const isManualSource = drpSource?.identifier === 'Manual';
					const hasEmptyDrpReference =
						(drpReference.identifier + '').trim() === '';

					const hasDrpNoteValue = drpNote?.identifier?.trim() !== '';

					if (hasEmptyDrpReference && isManualSource && hasDrpNoteValue) {
						filteredDistribution.push(distItem);
					}
				} else {
					if (
						distItem.typeCode == RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY ||
						distItem.typeCode == 'CME Prices'
					) {
						filteredDistribution.push(distItem);
					}
				}
			});

			externaldrpclassdata.forEach(d => {
				if (
					d.calQuarter ===
						'Q' +
							drpclassdataitem.extension?.accountingPeriod?.[0]
								?.periodIdentifier &&
					d.calYear === drpclassdataitem.extension?.accountingPeriod?.[0]?.year
				) {
					const mappedEndorsement = mapDRPApiResponseToEndorsementModel(d);
					filteredDistribution.push(mappedEndorsement);
				}
			});

			drpclassdataitem.extension.distribution = filteredDistribution;
			drpclassdataitem.extension.distribution = drpclassdataitem.extension.distribution.filter(
				(d: any) => d.typeCode != RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY
			);

			drpclassdataitem.extension.distribution.push(
				DRP_Quarterly_Endorsement_Summary()
			);
			tempdrpdata.push(drpclassdataitem);
		});
	}
	return tempdrpdata;
};
const updatedrpcomponentendorsementandsummary = (
	externaldrpdata: Array<any>,
	DRPCOMPDATA: any,
	state: any,
	year: number
) => {
	const externaldrpcomponentdata = externaldrpdata.filter((data: any) => {
		return data.endorsementType === '832';
	});

	let tempdrpcomponentdata: any = [];
	if (externaldrpcomponentdata.length > 0) {
		DRPCOMPDATA.forEach((drpcomponentdata: any) => {
			const distribution = drpcomponentdata?.extension?.distribution || [];
			let filteredDistribution: any = [];
			distribution.forEach((distItem: any) => {
				distItem.quantity = distItem.quantity.filter(
					(d: any) => d.typeCode != ''
				);

				if (distItem.typeCode === 'Endorsement') {
					const drpReferencesarray = distItem?.dRPReferences || [];
					const drpReference = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Reference'
					);
					const drpSource = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Source'
					);
					const drpNote = drpReferencesarray.find(
						ref => ref.typeCode === 'DRP Note'
					);
					const isManualSource = drpSource?.identifier === 'Manual';
					const hasEmptyDrpReference =
						(drpReference.identifier + '').trim() === '';
					const hasDrpNoteValue = drpNote?.identifier?.trim() !== '';

					if (hasEmptyDrpReference && isManualSource && hasDrpNoteValue) {
						filteredDistribution.push(distItem);
					}
				} else {
					if (distItem.typeCode == RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY) {
						filteredDistribution.push(distItem);
					}
				}
			});
			externaldrpcomponentdata.forEach(d => {
				if (
					d.calQuarter ===
						'Q' +
							drpcomponentdata.extension?.accountingPeriod?.[0]
								?.periodIdentifier &&
					d.calYear === drpcomponentdata.extension?.accountingPeriod?.[0]?.year
				) {
					const mappedEndorsement = mapDRPApiResponseToDRPCOMPONENTEndorsementModel(
						d
					);
					filteredDistribution.push(mappedEndorsement);
				}
			});
			drpcomponentdata.extension.distribution = filteredDistribution;
			drpcomponentdata.extension.distribution = filteredDistribution;
			drpcomponentdata.extension.distribution = drpcomponentdata.extension.distribution.filter(
				(d: any) => d.typeCode != RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY
			);

			drpcomponentdata.extension.distribution.push(
				DRP_Comp_Quarterly_Endorsement_Summary()
			);
			tempdrpcomponentdata.push(drpcomponentdata);
		});
	}
	return tempdrpcomponentdata;
};

export const updateDRPdataWithExternalDrpdata = (
	externaldrpdata: Array<any>,
	state: any,
	year: number
) => {
	const RMData = state.calculatedSchema;
	const drpclassdata = RMData[RM_SECTION_CODES.DRP];
	const drpcompsdata = RMData[RM_SECTION_CODES.DRP_COMP];

	const uniqueDrpData = externaldrpdata.reduce((acc: any, item: any) => {
		if (
			!acc.find(
				(existingItem: any) => existingItem.drpReference === item.drpReference
			)
		) {
			acc.push(item);
		}
		return acc;
	}, []);
	const drpclassdataarray = updatedrpclassendorsementandsummary(
		uniqueDrpData,
		drpclassdata,
		state,
		year
	);
	const drpcomponentdataarray = updatedrpcomponentendorsementandsummary(
		uniqueDrpData,
		drpcompsdata,
		state,
		year
	);

	if (drpclassdataarray.length > 0) {
		state.calculatedSchema[RM_SECTION_CODES.DRP] = drpclassdataarray;
	}
	if (drpcomponentdataarray.length > 0) {
		state.calculatedSchema[RM_SECTION_CODES.DRP_COMP] = drpcomponentdataarray;
	}
};

/*DRP Mapping External Source data to COA Schema code End */

export const moveRMPostionDictionaryData = (state: COAInfo | any) => {
	const positionArray = [];
	if (state.calculatedSchema && state.calculatedSchema[Position_DICTIONARY]) {
		for (const commodity in state.calculatedSchema[Position_DICTIONARY]) {
			for (const key of state.calculatedSchema[Position_DICTIONARY][commodity][
				'keys'
			]) {
				const pc: PositionContainer =
					state.calculatedSchema[Position_DICTIONARY][commodity][key];
				positionArray.push(pc.toJson());
			}
		}
		state.calculatedSchema[RM_SECTION_CODES.POSITION] = positionArray;
	}
	return state;
};

const calculateAllPositions = (
	state: COAInfo,
	payload: { financialYear: string }
) => {
	for (const commodity in state.calculatedSchema[Position_DICTIONARY]) {
		for (const key of state.calculatedSchema[Position_DICTIONARY][commodity][
			'keys'
		]) {
			const pc: PositionContainer =
				state.calculatedSchema[Position_DICTIONARY][commodity][key];
			calculateAllThePositionsForMonthAndYear(state, {
				financialYear: payload.financialYear,
				settledprice: pc.periodSettlePrice,
				commodity: commodity,
				month: pc.accountingPeriod.identifier,
				year: pc.accountingPeriod.year,
			});
		}
	}
	return state;
};

const calculateAllThePositionsForMonthAndYear = (
	state: COAInfo,
	payload: {
		financialYear: any;
		settledprice: number;
		commodity: string;
		month: string;
		year: string;
	}
) => {
	const pc: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][payload.commodity][
			`${payload.month}_${payload.year}`
		];

	// calculate net for each record -- for new updated market price
	const listOfNonSummaryPosition = pc.positionList.filter(
		item =>
			item.typeCode != 'Summary' ||
			item.extension.typeCode != RM_TYPECODES.MTM_FUTURES_POSITION
	);

	listOfNonSummaryPosition.forEach(item => item.calculateNet());

	// calculate summary for each section
	if (pc.positionList.find(item => item.typeCode == RM_OPTIONS.FLOOR)) {
		evaluateOptionSummaryForItem(pc, RM_OPTIONS.FLOOR, RM_OPTIONS.FLOOR);
	}

	if (pc.positionList.find(item => item.typeCode == RM_OPTIONS.CEILING)) {
		evaluateOptionSummaryForItem(pc, RM_OPTIONS.CEILING, RM_OPTIONS.CEILING);
	}

	if (pc.positionList.find(item => item.typeCode == RM_OPTIONS.CONTRACT)) {
		evaluateOptionSummaryForItem(pc, RM_OPTIONS.CONTRACT, RM_OPTIONS.CONTRACT);
	}

	if (pc.positionList.find(item => item.typeCode == RM_OPTIONS.COLLAR)) {
		evaluateOptionSummaryForItem(pc, RM_OPTIONS.COLLAR, RM_OPTIONS.COLLAR);
	}

	pc.positionList = pc.positionList.filter(pm => {
		// condition to check if there are commodities without netmtm or only netmtm; if such commodity is found remove it.
		if (pc.positionList.filter(t => t.typeCode === pm.typeCode).length < 2) {
			return false;
		}
		return true;
	});
	evaluateGroupSummaryForPC(pc, payload, state);
	pc.positionList = [...pc.positionList];
	return state;
};
export const updatePostionWithMarketPrice = (
	state: COAInfo,
	payload: { marketPriceList: Array<MarketPrice> }
) => {
	for (const prop in state.calculatedSchema[Position_DICTIONARY]) {
		state.calculatedSchema[Position_DICTIONARY][prop]['keys'].forEach(
			(element: string) => {
				const pc: PositionContainer =
					state.calculatedSchema[Position_DICTIONARY][prop][element];
				const foundItem = payload.marketPriceList.find(
					item =>
						item.symbolCode == pc.code.Commodity &&
						item.month == pc.accountingPeriod.identifier &&
						item.year == pc.accountingPeriod.year
				);
				// console.log('FoundItem -- ', foundItem);
				if (foundItem) {
					pc.periodSettlePrice = foundItem.settlementPriceAmount;
					pc.positionList.forEach(
						item => (item.getPeriodSettlePrice = () => pc.periodSettlePrice)
					);
				}
			}
		);
	}
	return state;
};

const evaluateOptionSummaryForItem = (
	pc: PositionContainer,
	typeCode: string,
	extTypeCode: string
) => {
	switch (typeCode) {
		case RM_OPTIONS.CEILING:
			{
				const netMtm = new NetCeilingMTM();
				netMtm.evaluate(
					pc.positionList.filter(
						item =>
							item.typeCode == typeCode &&
							item.extension.typeCode == extTypeCode
					)
				);

				if (pc.positionList.find(item => item instanceof NetCeilingMTM)) {
					pc.positionList.splice(
						pc.positionList.findIndex(item => item instanceof NetCeilingMTM),
						1
					);
				}

				pc.positionList.push(netMtm);
			}
			break;
		case RM_OPTIONS.COLLAR:
			{
				const netMtm = new NetCollarMTM();
				netMtm.evaluate(
					pc.positionList.filter(
						item =>
							item.typeCode == typeCode &&
							(item.extension.typeCode == RM_OPTIONS.CEILING ||
								item.extension.typeCode == RM_OPTIONS.FLOOR)
					)
				);

				if (pc.positionList.find(item => item instanceof NetCollarMTM)) {
					pc.positionList.splice(
						pc.positionList.findIndex(item => item instanceof NetCollarMTM),
						1
					);
				}

				pc.positionList.push(netMtm);
			}
			break;
		case RM_OPTIONS.CONTRACT:
			{
				const netMtm = new NetContractMTM();
				netMtm.evaluate(
					pc.positionList.filter(
						item =>
							item.typeCode == typeCode &&
							item.extension.typeCode == extTypeCode
					)
				);

				if (pc.positionList.find(item => item instanceof NetContractMTM)) {
					pc.positionList.splice(
						pc.positionList.findIndex(item => item instanceof NetContractMTM),
						1
					);
				}

				pc.positionList.push(netMtm);
			}
			break;
		case RM_OPTIONS.FLOOR:
			{
				const netMtm = new NetFloorMTM();
				netMtm.evaluate(
					pc.positionList.filter(
						item =>
							item.typeCode == typeCode &&
							item.extension.typeCode == extTypeCode
					)
				);

				if (pc.positionList.find(item => item instanceof NetFloorMTM)) {
					pc.positionList.splice(
						pc.positionList.findIndex(item => item instanceof NetFloorMTM),
						1
					);
				}

				pc.positionList.push(netMtm);
			}
			break;
	}
};

const evaluateGroupSummaryForPC = (
	pc: PositionContainer,
	payload: {
		month: string;
		year: string;
		commodity: string;
		financialYear: number;
	},
	state: any
) => {
	// section for performing group summary
	let listofSummary: PositionSummaryModel[] | any = pc.positionList.filter(
		item => item.typeCode == RM_SECTION_CODES.SUMMARY
	);
	const listOfNonSummary = pc.positionList.filter(
		item => item.typeCode != RM_SECTION_CODES.SUMMARY
	);
	// pass all the positions to summary object for evaluation
	listofSummary.forEach((item: PositionSummaryModel) =>
		item.evaluate(listOfNonSummary, payload, state)
	);
};
const addPositionOfType = (
	state: COAInfo,
	payload: {
		type: string;
		commodity: string;
		month: string;
		year: string;
		settledprice: number;
		financialYear: any;
	}
) => {
	const pc: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][payload.commodity][
			`${payload.month}_${payload.year}`
		];
	switch (payload.type) {
		case RM_OPTIONS.CEILING:
			pc.addCeiling();
			evaluateOptionSummaryForItem(pc, RM_OPTIONS.CEILING, RM_OPTIONS.CEILING);
			break;
		case RM_OPTIONS.COLLAR:
			pc.addCollar();
			evaluateOptionSummaryForItem(pc, RM_OPTIONS.COLLAR, RM_OPTIONS.COLLAR);
			break;
		case RM_OPTIONS.CONTRACT:
			pc.addContract();
			evaluateOptionSummaryForItem(
				pc,
				RM_OPTIONS.CONTRACT,
				RM_OPTIONS.CONTRACT
			);
			break;
		case RM_OPTIONS.FLOOR:
			pc.addFloor();
			evaluateOptionSummaryForItem(pc, RM_OPTIONS.FLOOR, RM_OPTIONS.FLOOR);
			break;
	}
	evaluateGroupSummaryForPC(pc, payload, state);

	return state;
};

const updateRMPositionvalidation = (state: COAInfo) => {
	const positiondict = state.calculatedSchema[Position_DICTIONARY];
	let allMadCapReferenceinRMRecord: any = {};
	let erroroccuredflag = false;

	for (const commodity in positiondict) {
		for (const key of positiondict[commodity]['keys']) {
			const pc: PositionContainer = positiondict[commodity][key];

			//Store all Manually Entered Madcap References in `allMadCapReferenceinRMRecord` Array
			pc.positionList
				.filter(
					d =>
						['Floor', 'Ceiling', 'Collar', 'Contract'].includes(d.typeCode) &&
						['Floor', 'Ceiling', 'Collar', 'Contract'].includes(
							d.extension.typeCode
						)
				)
				.forEach((p: PositionModel) => {
					if (!(p.typeCode === 'Collar' && p.extension.typeCode === 'Floor')) {
						//ignore Collar Floor
						if (
							p.positionReferences.MadCap_Reference &&
							p.positionReferences.MadCap_Reference !== '' &&
							parseInt(p.positionReferences.MadCap_Reference) !== 0
						) {
							p.validation.error = false;
							if (
								parseInt(p.positionReferences.MadCap_Reference) in
								allMadCapReferenceinRMRecord
							) {
								allMadCapReferenceinRMRecord[
									parseInt(p.positionReferences.MadCap_Reference)
								].count += 1;
								allMadCapReferenceinRMRecord[
									parseInt(p.positionReferences.MadCap_Reference)
								].elementarray.push({
									ele: p,
									commodity:
										RM_COMMODITIES.CHEESE.SYMBOL === commodity
											? 'Cheese'
											: RM_COMMODITIES.CLASS3.SYMBOL === commodity
											? 'Class 3'
											: 'Class 4',
									month: pc.accountingPeriod.identifier,
									year: pc.accountingPeriod.year,
								});
							} else {
								allMadCapReferenceinRMRecord[
									parseInt(p.positionReferences.MadCap_Reference)
								] = {
									count: 1,
									elementarray: [],
								};
								allMadCapReferenceinRMRecord[
									parseInt(p.positionReferences.MadCap_Reference)
								].elementarray.push({
									ele: p,
									commodity:
										RM_COMMODITIES.CHEESE.SYMBOL === commodity
											? 'Cheese'
											: RM_COMMODITIES.CLASS3.SYMBOL === commodity
											? 'Class 3'
											: 'Class 4',
									month: pc.accountingPeriod.identifier,
									year: pc.accountingPeriod.year,
								});
							}
						} else {
							if (
								p.positionReferences.Position_Note === '' &&
								p.positionReferences.Order_Status === 'Manual'
							) {
								if (
									!(p.typeCode === 'Collar' && p.extension.typeCode === 'Floor')
								) {
									// ignore the collar floor
									erroroccuredflag = true;
									p.validation.error = true;
									p.validation.message =
										'Should either have MadCap Reference Or Comment.';
								} else {
									p.validation.error = false;
									p.validation.message = '';
								}
							} else {
								p.validation.error = false;
								p.validation.message = '';
							}
						}
					}
				});
		}
	}

	for (let k in allMadCapReferenceinRMRecord) {
		if (allMadCapReferenceinRMRecord[k].count >= 2) {
			allMadCapReferenceinRMRecord[
				k
			].elementarray = allMadCapReferenceinRMRecord[k].elementarray.map(
				(element: any, index: number) => {
					// if (element.ele.positionReferences.Order_Status === 'Manual') {
					erroroccuredflag = true;
					element.ele.validation.error = true;
					// }
					if (
						index ===
						allMadCapReferenceinRMRecord[k].elementarray.length - 1
					) {
						let duplicatelement =
							allMadCapReferenceinRMRecord[k].elementarray[index - 1];
						allMadCapReferenceinRMRecord[k].elementarray[
							index
						].ele.validation.message =
							'Duplicate MadCap entry from ' +
							duplicatelement.month +
							' ' +
							duplicatelement.year +
							' ' +
							duplicatelement.commodity;
					} else {
						let duplicatelement =
							allMadCapReferenceinRMRecord[k].elementarray[index + 1];
						allMadCapReferenceinRMRecord[k].elementarray[
							index
						].ele.validation.message =
							'Duplicate MadCap entry from ' +
							duplicatelement.month +
							' ' +
							duplicatelement.year +
							' ' +
							duplicatelement.commodity;
					}
					return element;
				}
			);
		} else {
			if (
				allMadCapReferenceinRMRecord[k].elementarray[0].ele.validation.error
			) {
				erroroccuredflag = true;
			}
		}
	}

	positiondict[RM_COMMODITIES.CHEESE.SYMBOL][
		'errorvalidation'
	] = erroroccuredflag;
	positiondict[RM_COMMODITIES.CLASS3.SYMBOL][
		'errorvalidation'
	] = erroroccuredflag;
	positiondict[RM_COMMODITIES.CLASS4.SYMBOL][
		'errorvalidation'
	] = erroroccuredflag;

	return state;
};

const deletePositionFromPC = (
	state: COAInfo,
	payload: {
		model: PositionModel;
		commodity: string;
		month: string;
		year: string;
		settledprice: number;
		financialYear: any;
	}
) => {
	let deletedmadcapreference =
		payload.model.positionReferences.MadCap_Reference;
	const pc: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][payload.commodity][
			`${payload.month}_${payload.year}`
		];

	if (pc.positionList.indexOf(payload.model) != -1) {
		if (payload.model.typeCode == RM_OPTIONS.COLLAR) {
			const foundList = pc.positionList.filter(
				item =>
					item.typeCode == RM_OPTIONS.COLLAR &&
					item.identifierSet.identifier ==
						payload.model.identifierSet.identifier
			);
			for (const foundItem of foundList) {
				pc.positionList.splice(pc.positionList.indexOf(foundItem), 1);
			}
		} else {
			pc.positionList.splice(pc.positionList.indexOf(payload.model), 1);
		}
	}

	// remove mtm when all item of type are deleted

	if (
		!removeMTMRowFromList(
			pc,
			payload.model.typeCode,
			payload.model.extension.typeCode
		)
	) {
		evaluateOptionSummaryForItem(
			pc,
			payload.model.typeCode,
			payload.model.extension.typeCode
		);
	}

	evaluateGroupSummaryForPC(pc, payload, state);

	pc.positionList = [...pc.positionList];

	if (payload.commodity == RM_COMMODITIES.CLASS3.SYMBOL) {
		updateCalcucationForCheeseAfterClass3Update(
			RM_COMMODITIES.CHEESE.SYMBOL,
			payload,
			state
		);
	} else if (payload.commodity == RM_COMMODITIES.CHEESE.SYMBOL) {
		updateCalcucationForCheeseAfterClass3Update(
			RM_COMMODITIES.CLASS3.SYMBOL,
			payload,
			state
		);
	}

	// Update Validation after the deletion of the position
	let temp = updateRMPositionvalidation(state);
	return state;
};

const updateNoofContractAndVolumeForCollar = (
	state: COAInfo,
	payload: {
		model: PositionModel;
		commodity: string;
		month: string;
		year: string;
		fieldName: PositionEditFieldType;
		floorquantity: number;
	}
): PositionContainer => {
	const pc: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][payload.commodity][
			`${payload.month}_${payload.year}`
		];
	if (
		payload.model.typeCode == RM_OPTIONS.COLLAR &&
		payload.model.extension.typeCode == RM_OPTIONS.FLOOR
	) {
		const ceiling = pc.positionList.find(
			item =>
				item.typeCode == RM_OPTIONS.COLLAR &&
				item.extension.typeCode == RM_OPTIONS.CEILING &&
				item.identifierSet.identifier == payload.model.identifierSet.identifier
		);
		if (ceiling) {
			const model = new PositionModel();
			model.typeCode = payload.model.typeCode;
			model.identifierSet = payload.model.identifierSet;
			model.extension.typeCode = RM_OPTIONS.CEILING;
			model.extension = { ...ceiling.extension };
			model.quantity = { ...ceiling.quantity };
			model.positionReferences = { ...ceiling.positionReferences };
			model.getPeriodSettlePrice = () => pc.periodSettlePrice;
			model.volumeInOneContract = Comm_SymbolTo_Volume_Dict[payload.commodity];
			model.divisor = Comm_Divisor_Dict[payload.commodity];
			model.multiplier = Comm_Multiplier_Dict[payload.commodity];

			model.setNumberOfContracts(payload.floorquantity * -1);
			const index = pc.positionList.indexOf(ceiling);
			if (index != -1 && payload.fieldName) {
				pc.positionList[index] = model;
			}
		}
	}
	return pc;
};

const updatePositionDataWithPos = (
	state: COAInfo,
	payload: {
		settledprice: number;
		financialYear: number;
		model: PositionModel;
		commodity: string;
		month: string;
		year: string;
		fieldName: PositionEditFieldType;
		value: number;
	}
) => {
	let pc: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][payload.commodity][
			`${payload.month}_${payload.year}`
		];
	//since in collar the volume filled is disabled, so trigger the no of contract and volume update when the collar floor No of contract changes.
	if (
		payload.model.typeCode == RM_OPTIONS.COLLAR &&
		PositionEditFieldType.NoOfContracts === payload.fieldName
	) {
		let floorquantity = payload.value;
		pc = updateNoofContractAndVolumeForCollar(state, {
			model: payload.model,
			commodity: payload.commodity,
			month: payload.month,
			year: payload.year,
			fieldName: payload.fieldName,
			floorquantity: floorquantity,
		});
	}
	const index = pc.positionList.indexOf(payload.model);
	if (index != -1 && payload.fieldName) {
		const model = new PositionModel();
		model.typeCode = payload.model.typeCode;
		model.identifierSet = payload.model.identifierSet;
		model.extension.typeCode = payload.model.extension.typeCode;
		model.extension = { ...payload.model.extension };
		model.quantity = { ...payload.model.quantity };
		model.positionReferences = { ...payload.model.positionReferences };
		model.getPeriodSettlePrice = () => pc.periodSettlePrice;
		model.volumeInOneContract = Comm_SymbolTo_Volume_Dict[payload.commodity];
		model.divisor = Comm_Divisor_Dict[payload.commodity];
		model.multiplier = Comm_Multiplier_Dict[payload.commodity];

		switch (payload.fieldName) {
			case PositionEditFieldType.Strike:
				model.setStrike(payload.value);
				break;
			case PositionEditFieldType.NoOfContracts:
				model.setNumberOfContracts(payload.value);
				break;

			case PositionEditFieldType.AdminFee:
				model.setAdminFee(payload.value);
				break;
			case PositionEditFieldType.Premium:
				model.setPremium(payload.value);
				break;
			case PositionEditFieldType.MadCap_Reference:
				model.positionReferences.MadCap_Reference = `${payload.value}`;
				break;
		}

		// Commented code was causing position row to switch
		// pc.positionList.splice(index, 1);
		// pc.positionList.push(model);
		pc.positionList[index] = model;
	}
	// console.log('##### update position at index: ' + index, payload.model);
	if (index != -1) {
		// make object of netmtm based on the typecode in model
		evaluateOptionSummaryForItem(
			pc,
			payload.model.typeCode,
			payload.model.extension.typeCode
		);
	}

	evaluateGroupSummaryForPC(pc, payload, state);
	pc.positionList = [...pc.positionList];
	if (payload.commodity == RM_COMMODITIES.CLASS3.SYMBOL) {
		updateCalcucationForCheeseAfterClass3Update(
			RM_COMMODITIES.CHEESE.SYMBOL,
			payload,
			state
		);
	} else if (payload.commodity == RM_COMMODITIES.CHEESE.SYMBOL) {
		updateCalcucationForCheeseAfterClass3Update(
			RM_COMMODITIES.CLASS3.SYMBOL,
			payload,
			state
		);
	}

	let temp = updateRMPositionvalidation(state);
	return state;
};

const updateCalcucationForCheeseAfterClass3Update = (
	commodity: string,
	payload: {
		month: string;
		year: string;
		commodity: string;
		financialYear: number;
	},
	state: any
) => {
	let pcforSymbol: PositionContainer =
		state.calculatedSchema[Position_DICTIONARY][commodity][
			`${payload.month}_${payload.year}`
		];
	evaluateGroupSummaryForPC(
		pcforSymbol,
		{
			month: payload.month,
			year: payload.year,
			commodity: commodity,
			financialYear: payload.financialYear,
		},
		state
	);
	pcforSymbol.positionList = [...pcforSymbol.positionList];
};

export const updatePostionsDataWithMadCapData = (
	madcapPositionList: Array<MadCapPosition>,
	state: any,
	year: number
) => {
	const RMData = state.calculatedSchema;
	RMData.Header = updateHeaderWithDate(_.cloneDeep(RMData.Header));
	updatePositions(madcapPositionList, RMData, state);
	calculateAllPositions(state, { financialYear: `${year}` });
};

export const updateHeaderWithDate = (
	headerData: typeof RMHeader
): typeof RMHeader => {
	const foundIndex = headerData.documentReference.findIndex(
		item => item.typeCode == lblMadCapLastUpdate
	);
	if (foundIndex !== -1) {
		headerData.documentReference.splice(foundIndex, 1);
	}

	headerData.documentReference.push({
		typeCode: lblMadCapLastUpdate,
		identifier: '',
		documentDateTime: new Date().toUTCString(),
	});
	return headerData;
};

const removeMTMRowFromList = (
	pc: PositionContainer,
	typeCode: string,
	subTypeCode: string
) => {
	if (
		pc.positionList.filter(
			item =>
				item.typeCode == typeCode && item.extension.typeCode == subTypeCode
		).length == 0
	) {
		let foundindex = -1;
		foundindex = pc.positionList.findIndex(
			item =>
				item.typeCode == typeCode &&
				item.extension.typeCode == RM_TYPECODES.MTM_FUTURES_POSITION
		);
		if (foundindex !== -1) {
			pc.positionList.splice(foundindex, 1);
		}
		return true;
	} else {
		return false;
	}
};

export const updatePositions = (
	madcapPositionList: Array<MadCapPosition>,
	calcSchema: any,
	state: any
) => {
	const madcapidlist: string[] = [];
	// create a dictionary to access month-year and commodity specfic
	const dictionaryOfSymbolMonthYear = madcapPositionList.reduce(
		(prev: any, currentVal) => {
			const key = `${currentVal.commodityCode}-${currentVal.month}-${currentVal.year}`;
			madcapidlist.push(currentVal.identifier);
			if (prev[key]) {
				prev[key].push(currentVal);
			} else {
				prev[key] = [currentVal];
			}
			return prev;
		},
		{}
	);
	// console.log(calcSchema, dictionaryOfSymbolMonthYear);

	// loop on the position containers -- positions are located in container->extension->distrubutions
	// find the month year and symbol of the container
	// get the positon for this date range from the madcap dictionary
	// prepare list all the position in the date range to the container
	// make a list of all the map cap id
	// remove all the existing mad cap id
	// add new position to position container

	for (const commodity in calcSchema[Position_DICTIONARY]) {
		for (const key of calcSchema[Position_DICTIONARY][commodity]['keys']) {
			const pc: PositionContainer =
				calcSchema[Position_DICTIONARY][commodity][key];

			// change the month to index values 1 to 12
			const monthIndex =
				FULL_MONTHS.indexOf(pc.accountingPeriod.identifier) + 1;
			// get the commodity name as referedin madcap response api
			const madcap_comm_name = MADCAP_COMMODITY_NAME_MAP[pc.code.Commodity];

			const keyForMadCapDict = `${madcap_comm_name}-${monthIndex}-${pc.accountingPeriod.year}`;

			const madCapListForMonthYear =
				dictionaryOfSymbolMonthYear[keyForMadCapDict];

			// remove all the existing madcap data and duplicate manual entries
			pc.positionList = pc.positionList.filter(pm => {
				let madcapref = pm.positionReferences.MadCap_Reference.toString();
				if (madcapidlist.includes(madcapref)) {
					return false;
				}
				return true;
			});

			// remove all the mtm if all position of type\subtype are removed
			[
				{ typeCode: RM_OPTIONS.FLOOR, subTypeCode: RM_OPTIONS.FLOOR },
				{ typeCode: RM_OPTIONS.CEILING, subTypeCode: RM_OPTIONS.CEILING },
				{ typeCode: RM_OPTIONS.CONTRACT, subTypeCode: RM_OPTIONS.CONTRACT },
				{ typeCode: RM_OPTIONS.COLLAR, subTypeCode: RM_OPTIONS.FLOOR },
				{ typeCode: RM_OPTIONS.COLLAR, subTypeCode: RM_OPTIONS.CEILING },
			].forEach(element => {
				removeMTMRowFromList(pc, element.typeCode, element.subTypeCode);
			});

			if (madCapListForMonthYear) {
				// console.log(keyForMadCapDict);

				console.log(
					'_____',
					pc.accountingPeriod.identifier,
					pc.accountingPeriod.year,
					madCapListForMonthYear
				);

				// generate a rmi position object for each madcap position data
				madCapListForMonthYear.forEach((madCapPosition: MadCapPosition) => {
					// Get position based on sub type only
					// refer to mappin on excel sheet https://landolakes.sharepoint.com.mcas.ms/:x:/r/sites/RiskMarginInsightsPhase2/_layouts/15/Doc.aspx?sourcedoc=%7B76F2B010-50D2-4D90-9036-D539038A7B1E%7D&file=MadCap%20Mapping.xlsx&action=default&mobileredirect=true

					//add record only if the status is not cancelled -- status code != 8

					if (madCapPosition.statusCode != '8') {
						if (madCapPosition.subTypeCode == '2') {
							// order type = “Day”
							if (
								madCapPosition.statusCode == '4' ||
								madCapPosition.statusCode == '5'
							) {
								// Status = “Filled” or “Settled”
								addtoRMEntry(pc, madCapPosition, commodity);
							}
						} else {
							// Order Type <> “Day”
							addtoRMEntry(pc, madCapPosition, commodity);
						}
					}
				});
				// change the list to trigger rerender
				const finanYear =
					parseInt(
						calcSchema['copData']['BASIC INFO']['accountingPeriod']['year']
					) + 1;
				evaluateGroupSummaryForPC(
					pc,
					{
						month: pc.accountingPeriod.identifier,
						year: pc.accountingPeriod.year,
						commodity: pc.code.Commodity,
						financialYear: finanYear,
					},
					{ calculatedSchema: calcSchema }
				);
				pc.positionList = [...pc.positionList];
				pc.positionList = getsortedpositionList(pc.positionList);
				// console.log('After adding madcap data', pc.positionList);
			}
		}
	}
	let temp = updateRMPositionvalidation(state);

	return calcSchema;
};

const deleteduplicateRMEntry = (
	pc: PositionContainer,
	madCapPosition: MadCapPosition
) => {
	// Delete duplicate Manual position Only if the madcap data is to be added
	pc.positionList = pc.positionList.filter((p: any) => {
		if (['Floor', 'Contract', 'Ceiling'].includes(p.typeCode)) {
			if (p.positionReferences.MadCap_Reference == madCapPosition.identifier) {
				return false;
			}
			return true;
		}
		return true;
	});
};

const addtoRMEntry = (
	pc: PositionContainer,
	madCapPosition: MadCapPosition,
	commodity: string
) => {
	{
		const positionType = Get_ContractTypeFromSubType(
			madCapPosition.subTypeCode
		);

		if (commodity === RM_COMMODITIES.CHEESE.SYMBOL) {
			madCapPosition.administrativeFeeAmount =
				madCapPosition.administrativeFeeAmount / 10;
			// madCapPosition.premiumAmount = madCapPosition.premiumAmount / 10;
		}
		let contractAmountMultiplier = 1;
		let secondTypeCode = '';
		if (positionType == ContractType.Collar) {
			if (madCapPosition.lineTypeCode == '3') {
				secondTypeCode = ContractType.Floor;
				contractAmountMultiplier = 1;
			} else if (madCapPosition.lineTypeCode == '2') {
				secondTypeCode = ContractType.Ceiling;
				contractAmountMultiplier = -1;
			}
		} else {
			secondTypeCode = positionType;
			// removed positionType == ContractType.Ceiling from if since Quantity/Contract is positive for ceiling
			if (positionType == ContractType.Contract) {
				contractAmountMultiplier = -1;
			}
		}

		//incase of collar update

		//madCapPosition.contractId;

		const position = new PositionModel();
		position.typeCode = positionType;
		position.extension.typeCode = secondTypeCode;

		position.volumeInOneContract = Comm_SymbolTo_Volume_Dict[pc.code.Commodity];
		position.divisor = Comm_Divisor_Dict[pc.code.Commodity];
		position.multiplier = Comm_Multiplier_Dict[pc.code.Commodity];

		position.getPeriodSettlePrice = () => {
			return pc.periodSettlePrice;
		};

		position.setStrike(madCapPosition.strikePriceAmount);

		/// always divide by 200,000 collar should map it 20,000 when showing volume

		position.setNumberOfContracts(
			(madCapPosition.quantity * contractAmountMultiplier) / VolumeInOneContract
		);
		position.setAdminFee(madCapPosition.administrativeFeeAmount);
		position.setPremium(madCapPosition.premiumAmount);
		position.positionReferences.Position_Source = PositionSourceType.MadCap;
		position.positionReferences.MadCap_Reference = madCapPosition.identifier;
		position.positionReferences.Order_Status = MapStatusCodeToString(
			madCapPosition.statusCode
		);
		position.positionReferences.Position_Note = '';
		position.positionReferences.MadCap_Ref_OID = TrimSpaceFromMadCapID(
			madCapPosition.contractId
		);
		pc.positionList.push(position);
		evaluateOptionSummaryForItem(
			pc,
			position.typeCode,
			position.extension.typeCode
		);

		// console.log('New Found position', JSON.stringify(position));
	}
};

// update calculation
const updateCalculation = (state = initialState, action: ActionSchema) => {
	const { screen, screenSchema, section, calledOn } = action.payload;

	let ob = {};
	if (calledOn === 'UPDATE') {
		if (screen) {
			ob = {
				...state.calculatedSchema,
				[section]: {
					...state.calculatedSchema[section],
					[screen]: screenSchema,
				},
			};
		} else {
			ob = {
				...state.calculatedSchema,
				[section]: screenSchema,
			};
		}
	} else if (calledOn === 'INIT') {
		ob = screenSchema;
	}

	//assiging to coa state
	console.log('About to update the calculated schema. catch me');
	state = updateObject(state, {
		calculatedSchema: ob,
	});
	return state;
};

// reset rm
const resetRM = (state = initialState, action: ActionSchema) => {
	//assiging to coa state
	state = Object.assign({}, state, {
		isDataModified: false,
		calculatedSchema: updateRefrence(state.mappedSchema),
		loading: false,
		formError: null,
		reset: true,
	});

	const RM_SCHEMA = state.calculatedSchema;
	if (RM_SCHEMA[RM_SECTION_CODES.POSITION]) {
		RM_SCHEMA[Position_DICTIONARY] = reducePositionListInfoDictionary(
			RM_SCHEMA[RM_SECTION_CODES.POSITION]
		);
	}
	return state;
};

//reset cop
const resetCOP = (state = initialState, action: ActionSchema) => {
	//assiging to coa state
	state = Object.assign({}, state, {
		isDataModified: false,
		calculatedSchema: updateRefrence(state.mappedSchema),
		loading: false,
		formError: null,
		reset: false,
	});

	return state;
};

const groupOperationForecast = (
	data: GenericSchema,
	isExisting: boolean,
	copYear: string,
	copMonth: string
) => {
	let groupedData = {};
	let months: Array<GenericSchema> = [];
	let quarters: Array<GenericSchema> = [];
	let year: Array<GenericSchema> = [];
	if (isExisting) {
		months = data.filter((item: GenericSchema) => {
			return !!item['extension']['accountingPeriod'][0]['identifier'];
		});
		quarters = data.filter((item: GenericSchema) => {
			return (
				!item['extension']['accountingPeriod'][0]['identifier'] &&
				!!item['extension']['accountingPeriod'][0]['periodIdentifier']
			);
		});
		year = data.filter((item: GenericSchema) => {
			return (
				!item['extension']['accountingPeriod'][0]['identifier'] &&
				!item['extension']['accountingPeriod'][0]['periodIdentifier']
			);
		});

		if (
			months[0]['extension']['accountingPeriod'][0]['identifier'] !== copMonth
		) {
			const janIndex = months.findIndex((item: GenericSchema) => {
				return (
					item['extension']['accountingPeriod'][0]['identifier'] ===
					FULL_MONTHS[0]
				);
			});
			const monthsToShift = months.splice(janIndex, months.length - 1);
			months.unshift(...monthsToShift);
			for (let i = 0; i < 12; i++) {
				months[i]['extension']['accountingPeriod'][0]['year'] =
					copMonth === FULL_MONTHS[0] ? String(Number(copYear) + 1) : copYear;
			}
		}
	} else {
		const currentYear: string =
			copMonth === FULL_MONTHS[0] ? String(Number(copYear) + 1) : copYear;
		const monthNames = FULL_MONTHS;

		for (let i = 1; i <= 12; i++) {
			const month = {
				typeCode: RM_SECTION_CODES.OPERATION_FORECAST,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: [
						new PeriodSummaryForForecast('', '', 0, 'CoA Account', ''),
					],
					accountingPeriod: [
						{
							identifier: monthNames[i - 1],
							periodIdentifier: String(Math.ceil(i / 3)),
							year: currentYear,
						},
					],
				},
			};
			months.push(month);
		}

		for (let i = 1; i <= 4; i++) {
			const quarter = {
				typeCode: RM_SECTION_CODES.OPERATION_FORECAST,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: [
						new PeriodSummaryForForecast('', '', 0, 'CoA Account', ''),
					],
					accountingPeriod: [
						{ identifier: '', periodIdentifier: String(i), year: currentYear },
					],
				},
			};
			quarters.push(quarter);
		}

		year = [
			{
				typeCode: RM_SECTION_CODES.OPERATION_FORECAST,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: [
						new PeriodSummaryForForecast('', '', 0, 'CoA Account', ''),
					],
					accountingPeriod: [
						{ identifier: '', periodIdentifier: '', year: currentYear },
					],
				},
			},
		];
	}

	groupedData = {
		[RM_OPERATING_FORECAST_SECTION_CODES.MONTHS]: months,
		[RM_OPERATING_FORECAST_SECTION_CODES.QUARTERS]: quarters,
		[RM_OPERATING_FORECAST_SECTION_CODES.YEAR]: year,
	};
	return groupedData;
};

const mapCOPForecast = (
	incomeData: GenericSchema,
	expenseData: GenericSchema,
	copYear: string
) => {
	const data = incomeData.concat(expenseData);
	let distributionArray = [];
	distributionArray.push(
		new PeriodSummaryForForecast(
			'',
			'',
			0,
			'CoA Account',
			'91',
			'Allocation Type',
			''
		)
	);
	distributionArray.push(
		new PeriodSummaryForForecast(
			'',
			'',
			0,
			'CoA Account',
			'92',
			'Allocation Type',
			''
		)
	);
	distributionArray.push(
		new PeriodSummaryForForecast(
			'',
			'',
			0,
			'CoA Account',
			'93',
			'Allocation Type',
			''
		)
	);
	for (let i = 0; i < data.length; i++) {
		let dist = new PeriodSummaryForForecast(
			'',
			'',
			0,
			'CoA Account',
			data[i].identifier,
			data[i].extension.code[1].typeCode,
			data[i].extension.code[1].content,
			data[i].extension.code.find((ob: GenericSchema) => {
				return ob.typeCode === COA_TYPECODES.DEFAULT_PERCENT;
			})?.content
		);
		distributionArray.push(dist);
	}

	return [
		{
			typeCode: RM_SECTION_CODES.COP_FORECAST,
			extension: {
				code: [
					{ content: '', typeCode: 'Quote Type' },
					{ content: '', typeCode: 'Commodity' },
				],
				distribution: distributionArray,
				accountingPeriod: [
					{
						identifier: '',
						periodIdentifier: '',
						year: String(Number(copYear) + 1),
					},
				],
			},
		},
	];
};

const createPositionDistributionArray = () => {
	const distributionList = [
		new PositionSummary(RM_TYPECODES.SUMMARY, COMMODITY_SUMMARY.HEDGE_YIELD, 1),
		new PositionSummary(RM_TYPECODES.SUMMARY, COMMODITY_SUMMARY.CASH_YIELD, 2),
		new PositionSummary(
			RM_TYPECODES.SUMMARY,
			COMMODITY_SUMMARY.NET_EXPOSURE,
			3
		),
		new PositionSummary(
			RM_TYPECODES.SUMMARY,
			COMMODITY_SUMMARY.CL3_BREAKEVEN,
			4
		),
		new PositionSummary(
			RM_TYPECODES.SUMMARY,
			COMMODITY_SUMMARY.CONTRACT_SETTLE,
			5
		),
		new PositionSummary(
			RM_TYPECODES.SUMMARY,
			COMMODITY_SUMMARY.CL3_MILK_REVENUE,
			6
		),
		new PositionSummary(RM_TYPECODES.SUMMARY, COMMODITY_SUMMARY.NET_MARIGIN, 7),
	];
	return distributionList;
};

const createDRPClassDistributionArray = () => {
	const distributionList = [
		new DRP(RM_TYPECODES.CME_PRICE, RM_TYPECODES.CLASS3_PRICE, 1),
		new DRP(RM_TYPECODES.CME_PRICE, RM_TYPECODES.CLASS4_PRICE, 2),
		// removing as inputs are no longer used with new drp clas
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.DECLARED_PRODUCTION_QTR, 3),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.DECLARED_PRODUCTION_MO, 4),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.FLOOR_PRICE_CLASS3, 5),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.FLOOR_PRICE_CLASS4, 6),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.PERCENT_MILK_COV, 7),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.CLASS3_WEIGHT_FACTOR, 8),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.CLASS4_WEIGHT_FACTOR, 9),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.PROTECTION_FACTOR_QTR, 10),
		// new DRP(RM_TYPECODES.INPUTS, RM_TYPECODES.PREM_PAID, 11),
		new DRP(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.EXPECTED_REVENUE_GUARANTEE_POSITION,
			12
		),
		new DRP(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL,
			13
		),
		new DRP(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.MARKET_PRICE_WEIGHT,
			14
		),
		new DRP(RM_TYPECODES.EXPECTED_OUTPUTS, RM_TYPECODES.MTM, 15),
		new DRP(RM_TYPECODES.EXPECTED_OUTPUTS, RM_TYPECODES.MTM_MO, 16),
		new DRP(RM_TYPECODES.EXPECTED_OUTPUTS, RM_TYPECODES.MTM_QTR, 17),
		new DRP(
			RM_TYPECODES.ACTUAL_OUTPUTS,
			RM_TYPECODES.YIELD_ADJ_FACTOR_RATIO,
			18
		),
		new DRP(RM_TYPECODES.ACTUAL_OUTPUTS, RM_TYPECODES.PROBABLE_INDEMNITY, 19),
		new DRP(RM_TYPECODES.ACTUAL_OUTPUTS, RM_TYPECODES.MTM_ADJ, 20),
	];

	return distributionList;
};

const createDRPCompDistributionArray = () => {
	let distributionArray = [];
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_FORECASTED_PRICES,
			RM_TYPECODES.BUTTERFAT_$_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_FORECASTED_PRICES,
			RM_TYPECODES.PROTEIN_$_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_FORECASTED_PRICES,
			RM_TYPECODES.NON_FAT_SOLIDS_$_LBS,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_FORECASTED_PRICES,
			RM_TYPECODES.OTHER_SOLIDS_$_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.DECLARED_TESTS,
			RM_TYPECODES.BUTTERFAT_LBS_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.DECLARED_TESTS,
			RM_TYPECODES.PROTEIN_LBS_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.DECLARED_TESTS,
			RM_TYPECODES.NON_FAT_SOLIDS_$_LBS,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.DECLARED_TESTS,
			RM_TYPECODES.OTHER_SOLIDS_FIXED_LBS_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.DECLARED_PROD_QTR, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.DECLARED_PROD_MONTH, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.BUTTERFAT_QUOTE, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.PROTEIN_QUOTE, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.OTHER_SOLIDS_QUOTE, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.NON_FAT_SOLIDS_QUOTE, 0)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.INPUTS,
			RM_TYPECODES.COMPONENT_WEIGHTING_FACTOR,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.INPUTS,
			RM_TYPECODES.NON_FAT_BUTTERFAT_WEIGHTING_FACTOR,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.TOTAL_PRICE, 0)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.PERCENT_MILK_COVERED, 0)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.INPUTS,
			RM_TYPECODES.PROTECTION_FACTOR_QTR,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.INPUTS, RM_TYPECODES.PREMIUM_PAID, 0)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.EXPECTED_REVENUE_GUARANTEE,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.BEGIN_PROFITABLE_LEVEL,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.MARKET_PRICE_WEIGHTED,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.MTM_PER_CWT,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.MTM_PER_MONTH,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.EXPECTED_OUTPUTS,
			RM_TYPECODES.MTM_PER_QTR,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(RM_TYPECODES.ACTUAL_OUTPUTS, RM_TYPECODES.YF_RATIO, 0)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_OUTPUTS,
			RM_TYPECODES.PROBABLE_INDEMNITY,
			0
		)
	);
	distributionArray.push(
		new PeriodSummary(
			RM_TYPECODES.ACTUAL_OUTPUTS,
			RM_TYPECODES.MTM_YF_ADJUSTMENTS,
			0
		)
	);
	return distributionArray;
};

const createDRPCompData = (
	copMonth: string,
	copYear: number,
	prevYearJournalEntryLine: GenericSchema
) => {
	let drpArray = [];
	let distributionArray = createDRPCompDistributionArray();

	let quarterStart = Math.ceil((FULL_MONTHS.indexOf(copMonth) + 1) / 3);
	let currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

	let i = quarterStart;
	let year = new Date().getFullYear();
	if (FULL_MONTHS.indexOf(copMonth) === 0) {
		copYear++;
	}
	for (copYear; copYear < year; ) {
		const priorYearDrpCompData = getPriorYearDRPData(
			prevYearJournalEntryLine,
			String(i),
			String(copYear),
			RM_SECTION_CODES.DRP_COMP
		);
		if (!_.isEmpty(priorYearDrpCompData)) {
			drpArray.push(priorYearDrpCompData);
		} else {
			drpArray.push({
				typeCode: RM_SECTION_CODES.DRP_COMP,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: JSON.parse(JSON.stringify(distributionArray)),
					accountingPeriod: [
						{
							identifier: '',
							periodIdentifier: String(i),
							year: String(copYear),
						},
					],
				},
			});
		}
		if (i === 4) {
			i = 1;
			copYear++;
		} else {
			i++;
		}
	}

	if (copYear === year && i <= currentQuarter) {
		for (i; i <= currentQuarter; i++) {
			const priorYearDrpCompData = getPriorYearDRPData(
				prevYearJournalEntryLine,
				String(i),
				String(copYear),
				RM_SECTION_CODES.DRP_COMP
			);
			if (!_.isEmpty(priorYearDrpCompData)) {
				drpArray.push(priorYearDrpCompData);
			} else {
				drpArray.push({
					typeCode: RM_SECTION_CODES.DRP_COMP,
					extension: {
						code: [
							{ content: '', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: JSON.parse(JSON.stringify(distributionArray)),
						accountingPeriod: [
							{
								identifier: '',
								periodIdentifier: String(i),
								year: String(year),
							},
						],
					},
				});
			}
		}
	} else if (copYear > year) {
		year = copYear;
	}

	if (i === 5) {
		i = 1;
		year++;
	}

	for (let j = 0; j < 5; j++) {
		const priorYearDrpCompData = getPriorYearDRPData(
			prevYearJournalEntryLine,
			String(i),
			String(year),
			RM_SECTION_CODES.DRP_COMP
		);
		if (!_.isEmpty(priorYearDrpCompData)) {
			drpArray.push(priorYearDrpCompData);
		} else {
			drpArray.push({
				typeCode: RM_SECTION_CODES.DRP_COMP,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: JSON.parse(JSON.stringify(distributionArray)),
					accountingPeriod: [
						{
							identifier: '',
							periodIdentifier: String(i),
							year: String(year),
						},
					],
				},
			});
		}
		if (i === 4) {
			i = 1;
			year++;
		} else {
			i++;
		}
	}

	return drpArray;
};

//filter schema according to commodity
const filterPosition = (data: GenericSchema, commodity: string) => {
	return data.filter((item: GenericSchema) => {
		const propertyIndex = getPropertyIndex(
			item?.extension.code,
			RM_TYPECODES.COMMODITY
		);
		return item?.extension.code[propertyIndex].content === getSymbol(commodity);
	});
};

const restructurePositionsData = (
	data: GenericSchema,
	copMonth: string,
	copYear: number,
	prevYearJournalEntryLine: GenericSchema
) => {
	let restructedData: GenericSchema = [];

	let startingYear = Number(copYear);
	if (FULL_MONTHS.indexOf(copMonth) === 0) {
		startingYear = startingYear + 1;
	}

	const startDate = new Date(`${copMonth} ${startingYear}`);
	const numNextMonths = NUM_OF_CARDS;

	// for Class 3/ Class 4 / Cheese
	Object.keys(RM_COMMODITIES).forEach((key: string, i: number) => {
		const commodityData = filterPosition(data, key);
		const restructedDataForEachCommodity = [];
		if (
			key === RM_COMMODITIES.CLASS3.COMMODITY ||
			key === RM_COMMODITIES.CLASS4.COMMODITY ||
			key === RM_COMMODITIES.CHEESE.COMMODITY
		) {
			const commodity = parseJSON(stringifyJSON(RM_COMMODITIES))[key]['SYMBOL'];

			for (let i = 0; i < numNextMonths; i++) {
				const date = addMonths(new Date(startDate), i);
				const month = FULL_MONTHS[date.getMonth()];
				const year = date.getFullYear().toString();
				const quarter = Math.ceil((Number(month) + 1) / 3).toString();

				const currentYearPosition = getAlreadyExistedData(
					commodityData,
					month,
					year
				);

				if (!_.isEmpty(currentYearPosition)) {
					restructedDataForEachCommodity.push(currentYearPosition);
				} else {
					const priorYearPosition = getPriorYearPosition(
						prevYearJournalEntryLine,
						key,
						month,
						year
					);
					if (!_.isEmpty(priorYearPosition)) {
						restructedDataForEachCommodity.push(priorYearPosition);
					} else {
						const position = {
							typeCode: RM_TYPECODES.POSITION,
							extension: {
								code: [
									{ content: '', typeCode: 'Quote Type' },
									{ content: commodity, typeCode: 'Commodity' },
								],
								distribution: cloneDeep(createPositionDistributionArray()),
								accountingPeriod: [
									{ identifier: month, periodIdentifier: quarter, year: year },
								],
							},
						};
						restructedDataForEachCommodity.push(position);
					}
				}
			}
		}
		if (restructedDataForEachCommodity.length) {
			restructedData.push(...restructedDataForEachCommodity);
		} else {
			restructedData.push(...commodityData);
		}
	});

	return restructedData;
};

// to get already saved data
const getAlreadyExistedData = (
	data: GenericSchema,
	month: string,
	year: string
) => {
	let obj = {};
	(data || []).forEach((item: GenericSchema) => {
		if (
			item?.extension?.accountingPeriod[0]?.identifier === month &&
			item?.extension?.accountingPeriod[0]?.year === year
		) {
			obj = item;
		}
	});
	return obj;
};

const restructureDMCData = (
	data: GenericSchema,
	copMonth: string,
	copYear: number
) => {
	let startingYear = Number(copYear);

	if (FULL_MONTHS.indexOf(copMonth) === 0) {
		startingYear = startingYear + 1;
	}
	const startDate = new Date(`${copMonth} ${startingYear}`);
	let dmcData: GenericSchema = [];
	for (let monthInc = 0; monthInc < 12; monthInc++) {
		const date = addMonths(new Date(startDate), monthInc);
		const year = date.getFullYear().toString();
		const month = FULL_MONTHS[date.getMonth()];
		const quarter = Math.ceil((date.getMonth() + 1) / 3).toString();

		const alreadyExistedData = getAlreadyExistedData(data, month, year);
		let dmc = {};
		if (!_.isEmpty(alreadyExistedData)) {
			dmc = alreadyExistedData;
		} else {
			dmc = {
				typeCode: RM_TYPECODES.DMC,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: [
						new DMC(RM_TYPECODES.CORN, 1),
						new DMC(RM_TYPECODES.SBM, 2),
						new DMC(RM_TYPECODES.ALFALFA, 3),
						new DMC(RM_TYPECODES.PROJECTED_FEED_TOTAL, 4),
						new DMC(RM_TYPECODES.ACTUAL_FEED_TOTAL, 5),
						new DMC(RM_TYPECODES.ALL_MILK, 6),
						new DMC(RM_TYPECODES.MARGIN_OVER_FEED, 7),
						new DMC(RM_TYPECODES.DMC_IDEMNITY, 8),
						new DMC(RM_TYPECODES.INDEMNITY_PAYMENT, 9),
						new DMC(RM_TYPECODES.NET_PAYMENT, 10),
						new DMC(RM_TYPECODES.CUMMULATIVE_TOTAL_RETURN, 11),
					],
					accountingPeriod: [
						{
							identifier: month,
							periodIdentifier: quarter,
							year: year,
						},
					],
				},
			};
		}
		dmcData.push(dmc);
	}
	return dmcData;
};

const restructureDRPClassData = (
	data: GenericSchema,
	copMonth: string,
	copYear: string,
	prevYearJournalEntryLine: GenericSchema
) => {
	let quarterStart = Math.ceil((FULL_MONTHS.indexOf(copMonth) + 1) / 3);
	const oldQuarterStart = Number(
		data[0]['extension']['accountingPeriod'][0]['periodIdentifier']
	);

	let distributionArray = createDRPClassDistributionArray();
	if (copMonth === FULL_MONTHS[0]) {
		data = data.filter((ob: GenericSchema) => {
			return ob['extension']['accountingPeriod'][0]['year'] !== copYear;
		});
	} else if (oldQuarterStart > quarterStart) {
		for (let i = 0; i < oldQuarterStart - quarterStart; i++) {
			const quater = oldQuarterStart - 1 - i;
			const priorYearDrpClassData = getPriorYearDRPData(
				prevYearJournalEntryLine,
				String(quater),
				data[0]['extension']['accountingPeriod'][0]['year'],
				RM_SECTION_CODES.DRP
			);
			let drpQuarter = {};
			if (!_.isEmpty(priorYearDrpClassData)) {
				drpQuarter = priorYearDrpClassData;
			} else {
				drpQuarter = {
					typeCode: RM_SECTION_CODES.DRP,
					extension: {
						code: [
							{ content: '', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: cloneDeep(distributionArray),
						accountingPeriod: [
							{
								identifier: '',
								periodIdentifier: String(quater),
								year: data[0]['extension']['accountingPeriod'][0]['year'],
							},
						],
					},
				};
			}
			data.unshift(drpQuarter);
		}
	} else if (oldQuarterStart < quarterStart) {
		data.splice(0, quarterStart - oldQuarterStart);
	}

	if (data.length < 10) {
		let quarter = Number(
			data[data.length - 1]['extension']['accountingPeriod'][0][
				'periodIdentifier'
			]
		);
		let year = Number(
			data[data.length - 1]['extension']['accountingPeriod'][0]['year']
		);
		const length = 10 - data.length;
		for (let i = 0; i < length; i++) {
			if (quarter === 4) {
				quarter = 0;
				year++;
			}
			quarter++;

			data.push({
				typeCode: RM_SECTION_CODES.DRP,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: cloneDeep(distributionArray),
					accountingPeriod: [
						{
							identifier: '',
							periodIdentifier: String(quarter),
							year: String(year),
						},
					],
				},
			});
		}
	} else if (data.length > 10) {
		while (data.length > 10) {
			data.pop();
		}
	}
	return data;
};

const restructureDRPCompData = (
	data: GenericSchema,
	copMonth: string,
	copYear: string,
	prevYearJournalEntryLine: GenericSchema
) => {
	let quarterStart = Math.ceil((FULL_MONTHS.indexOf(copMonth) + 1) / 3);
	const oldQuarterStart = Number(
		data[0]['extension']['accountingPeriod'][0]['periodIdentifier']
	);

	let distributionArray = createDRPCompDistributionArray();
	if (copMonth === FULL_MONTHS[0]) {
		data = data.filter((ob: GenericSchema) => {
			return ob['extension']['accountingPeriod'][0]['year'] !== copYear;
		});
	} else if (oldQuarterStart > quarterStart) {
		for (let i = 0; i < oldQuarterStart - quarterStart; i++) {
			const quater = oldQuarterStart - 1 - i;
			const priorYearDrpCompData = getPriorYearDRPData(
				prevYearJournalEntryLine,
				String(quater),
				data[0]['extension']['accountingPeriod'][0]['year'],
				RM_SECTION_CODES.DRP_COMP
			);
			let drpQuarter = {};
			if (!_.isEmpty(priorYearDrpCompData)) {
				drpQuarter = priorYearDrpCompData;
			} else {
				drpQuarter = {
					typeCode: RM_SECTION_CODES.DRP_COMP,
					extension: {
						code: [
							{ content: '', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: JSON.parse(JSON.stringify(distributionArray)),
						accountingPeriod: [
							{
								identifier: '',
								periodIdentifier: String(quater),
								year: data[0]['extension']['accountingPeriod'][0]['year'],
							},
						],
					},
				};
			}
			data.unshift(drpQuarter);
		}
	} else if (oldQuarterStart < quarterStart) {
		data.splice(0, quarterStart - oldQuarterStart);
	}

	if (data.length < 10) {
		let quarter = Number(
			data[data.length - 1]['extension']['accountingPeriod'][0][
				'periodIdentifier'
			]
		);
		let year = Number(
			data[data.length - 1]['extension']['accountingPeriod'][0]['year']
		);
		const length = 10 - data.length;
		for (let i = 0; i < length; i++) {
			if (quarter === 4) {
				quarter = 0;
				year++;
			}
			quarter++;
			data.push({
				typeCode: RM_SECTION_CODES.DRP_COMP,
				extension: {
					code: [
						{ content: '', typeCode: 'Quote Type' },
						{ content: '', typeCode: 'Commodity' },
					],
					distribution: cloneDeep(distributionArray),
					accountingPeriod: [
						{
							identifier: '',
							periodIdentifier: String(quarter),
							year: String(year),
						},
					],
				},
			});
		}
	} else if (data.length > 10) {
		while (data.length > 10) {
			data.pop();
		}
	}
	return data;
};

const groupRMSummaryData = (
	data: GenericSchema,
	copMonth: string,
	copYear: number
) => {
	let months: Array<GenericSchema> = [];
	let year: Array<GenericSchema> = [];
	months = data.filter((item: GenericSchema) => {
		return !!item['extension']['accountingPeriod'][0]['identifier'];
	});
	year = data.filter((item: GenericSchema) => {
		return (
			!item['extension']['accountingPeriod'][0]['identifier'] &&
			!item['extension']['accountingPeriod'][0]['periodIdentifier']
		);
	});

	const dataStartMonthIndex = FULL_MONTHS.indexOf(
		months[0]['extension']['accountingPeriod'][0]['identifier']
	);
	const copStartMonthIndex = FULL_MONTHS.indexOf(copMonth);

	const startMonthIndex = months.findIndex((item: GenericSchema) => {
		return item['extension']['accountingPeriod'][0]['identifier'] === copMonth;
	});

	if (startMonthIndex > 0) {
		const monthsToShift = months.splice(startMonthIndex, months.length - 1);
		if (copStartMonthIndex > dataStartMonthIndex) {
			for (let i = 0; i < months.length; i++) {
				months[i]['extension']['accountingPeriod'][0]['year'] = String(
					copYear + 1
				);
			}
			if (dataStartMonthIndex === 0) {
				for (let i = 0; i < monthsToShift.length; i++) {
					monthsToShift[i]['extension']['accountingPeriod'][0]['year'] = String(
						copYear
					);
				}
			}
		} else {
			for (let i = 0; i < monthsToShift.length; i++) {
				monthsToShift[i]['extension']['accountingPeriod'][0]['year'] = String(
					copStartMonthIndex === 0 ? copYear + 1 : copYear
				);
			}
			if (copStartMonthIndex === 0) {
				for (let i = 0; i < months.length; i++) {
					months[i]['extension']['accountingPeriod'][0]['year'] = String(
						copYear + 1
					);
				}
			}
		}
		months.unshift(...monthsToShift);
		year[0]['extension']['accountingPeriod'][0]['year'] =
			months[11]['extension']['accountingPeriod'][0]['year'];
	}
	return {
		[RM_OPERATING_FORECAST_SECTION_CODES.MONTHS]: months,
		[RM_OPERATING_FORECAST_SECTION_CODES.YEAR]: year,
	};
};

// to get commodity symbol
const getSymbol = (commodity: string) => {
	switch (commodity) {
		case RM_COMMODITIES.CLASS3.COMMODITY:
			return RM_COMMODITIES.CLASS3.SYMBOL;
		case RM_COMMODITIES.CLASS4.COMMODITY:
			return RM_COMMODITIES.CLASS4.SYMBOL;
		case RM_COMMODITIES.CHEESE.COMMODITY:
			return RM_COMMODITIES.CHEESE.SYMBOL;
	}
};

// to get prior year position
const getPriorYearPosition = (
	prevYearJournalEntryLine: GenericSchema,
	commodity: string,
	month: string,
	year: string
) => {
	let obj = {};
	(prevYearJournalEntryLine[0]?.journalEntryLines || []).forEach(
		(item: GenericSchema) => {
			if (
				item?.typeCode === RM_SECTION_CODES.POSITION &&
				item?.extension?.accountingPeriod[0]?.year === year &&
				item?.extension?.accountingPeriod[0]?.identifier === month &&
				item?.extension?.code[1]?.content === getSymbol(commodity)
			) {
				obj = item;
			}
		}
	);
	return obj;
};

// to get prior year Drp Class data
const getPriorYearDRPData = (
	prevYearJournalEntryLine: GenericSchema,
	quater: string,
	year: string,
	drpTypeCode: string
) => {
	let obj = {};
	(prevYearJournalEntryLine[0]?.journalEntryLines || []).forEach(
		(item: GenericSchema) => {
			if (
				item?.typeCode === drpTypeCode &&
				item?.extension?.accountingPeriod[0]?.year === year &&
				item?.extension?.accountingPeriod[0]?.periodIdentifier === quater
			) {
				obj = item;
			}
		}
	);
	return obj;
};

const formatRMSchema = (
	schema: GenericSchema,
	copYear: string,
	copMonth: string,
	prevYearJournalEntryLine: GenericSchema
) => {
	const nodes: Array<GenericSchema> = [];
	const isNew: boolean = !schema.journalEntry.journalEntryLine.length;
	if (isNew) {
		const accountingPeriod =
			schema !== undefined
				? schema['showChartOfAccounts']['BASIC INFO']['accountingPeriod']
				: {};

		let startingYear = Number(accountingPeriod?.year);
		const startingMonth = accountingPeriod?.periodIdentifier;

		if (FULL_MONTHS.indexOf(copMonth) === 0) {
			startingYear = startingYear + 1;
		}
		const startDate = new Date(`${startingMonth} ${startingYear}`);
		const numNextMonths = NUM_OF_CARDS;

		for (let i = 0; i < numNextMonths; i++) {
			const date = addMonths(new Date(startDate), i);
			const month = FULL_MONTHS[date.getMonth()];
			const year = date.getFullYear().toString();
			const quarter = Math.ceil((i + 1) / 3);

			if (i < 12) {
				// DMC node
				const dmc = {
					typeCode: RM_TYPECODES.DMC,
					extension: {
						code: [
							{ content: 'FCST', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: [
							new DMC(RM_TYPECODES.CORN, 1),
							new DMC(RM_TYPECODES.SBM, 2),
							new DMC(RM_TYPECODES.ALFALFA, 3),
							new DMC(RM_TYPECODES.PROJECTED_FEED_TOTAL, 4),
							new DMC(RM_TYPECODES.ACTUAL_FEED_TOTAL, 5),
							new DMC(RM_TYPECODES.ALL_MILK, 6),
							new DMC(RM_TYPECODES.MARGIN_OVER_FEED, 7),
							new DMC(RM_TYPECODES.DMC_IDEMNITY, 8),
							new DMC(RM_TYPECODES.INDEMNITY_PAYMENT, 9),
							new DMC(RM_TYPECODES.NET_PAYMENT, 10),
							new DMC(RM_TYPECODES.CUMMULATIVE_TOTAL_RETURN, 11),
						],
						accountingPeriod: [
							{ identifier: month, periodIdentifier: quarter, year: year },
						],
					},
				};

				nodes.push(dmc);
			}

			// for Class 3/ Class 4 / Cheese
			Object.keys(RM_COMMODITIES).forEach((key: string, i: number) => {
				if (
					key === RM_COMMODITIES.CLASS3.COMMODITY ||
					key === RM_COMMODITIES.CLASS4.COMMODITY ||
					key === RM_COMMODITIES.CHEESE.COMMODITY
				) {
					const commodity = parseJSON(stringifyJSON(RM_COMMODITIES))[key][
						'SYMBOL'
					];

					const priorYearPosition = getPriorYearPosition(
						prevYearJournalEntryLine,
						key,
						month,
						year
					);
					if (!_.isEmpty(priorYearPosition)) {
						nodes.push(priorYearPosition);
					} else {
						const position = {
							typeCode: RM_TYPECODES.POSITION,
							extension: {
								code: [
									{ content: '', typeCode: 'Quote Type' },
									{ content: commodity, typeCode: 'Commodity' },
								],
								distribution: cloneDeep(createPositionDistributionArray()),
								accountingPeriod: [
									{ identifier: month, periodIdentifier: quarter, year: year },
								],
							},
						};
						nodes.push(position);
					}
				}
			});
		}
		const distributionList = createDRPClassDistributionArray();

		let quarterStart = Math.ceil((FULL_MONTHS.indexOf(copMonth) + 1) / 3);
		let currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

		let quatr = quarterStart;
		let year = new Date().getFullYear();
		let yearCOP = Number(copYear);
		if (FULL_MONTHS.indexOf(copMonth) === 0) {
			yearCOP++;
		}
		for (yearCOP; yearCOP < year; ) {
			const priorYearDrpClass = getPriorYearDRPData(
				prevYearJournalEntryLine,
				String(quatr),
				String(yearCOP),
				RM_SECTION_CODES.DRP
			);
			if (!_.isEmpty(priorYearDrpClass)) {
				nodes.push(priorYearDrpClass);
			} else {
				nodes.push({
					typeCode: RM_SECTION_CODES.DRP,
					extension: {
						code: [
							{ content: '', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: cloneDeep(distributionList),
						accountingPeriod: [
							{
								identifier: '',
								periodIdentifier: String(quatr),
								year: String(Number(yearCOP)),
							},
						],
					},
				});
			}
			if (quatr === 4) {
				quatr = 1;
				yearCOP++;
			} else {
				quatr++;
			}
		}
		if (yearCOP === year && quatr <= currentQuarter) {
			for (quatr; quatr <= currentQuarter; quatr++) {
				const priorYearDrpClass = getPriorYearDRPData(
					prevYearJournalEntryLine,
					String(quatr),
					String(year),
					RM_SECTION_CODES.DRP
				);
				if (!_.isEmpty(priorYearDrpClass)) {
					nodes.push(priorYearDrpClass);
				} else {
					nodes.push({
						typeCode: RM_SECTION_CODES.DRP,
						extension: {
							code: [
								{ content: '', typeCode: 'Quote Type' },
								{ content: '', typeCode: 'Commodity' },
							],
							distribution: cloneDeep(distributionList),
							accountingPeriod: [
								{
									identifier: '',
									periodIdentifier: String(quatr),
									year: String(Number(year)),
								},
							],
						},
					});
				}
			}
		} else if (yearCOP > year) {
			year = Number(yearCOP);
		}

		if (quatr === 5) {
			quatr = 1;
			year++;
		}

		for (let extraQuater = 0; extraQuater < 5; extraQuater++) {
			const priorYearDrpClass = getPriorYearDRPData(
				prevYearJournalEntryLine,
				String(quatr),
				String(year),
				RM_SECTION_CODES.DRP
			);
			if (!_.isEmpty(priorYearDrpClass)) {
				nodes.push(priorYearDrpClass);
			} else {
				nodes.push({
					typeCode: RM_SECTION_CODES.DRP,
					extension: {
						code: [
							{ content: '', typeCode: 'Quote Type' },
							{ content: '', typeCode: 'Commodity' },
						],
						distribution: cloneDeep(distributionList),
						accountingPeriod: [
							{
								identifier: '',
								periodIdentifier: quatr,
								year: String(year),
							},
						],
					},
				});
			}
			if (quatr === 4) {
				quatr = 1;
				year = Number(year) + 1;
			} else {
				quatr++;
			}
		}
	}

	const updatedDataWIthPosition = [
		...schema.journalEntry.journalEntryLine,
		...nodes,
	];

	//missed requirement ticket #67219
	const feedExpense = schema[SECTION_TYPE_CODE.EXPENSE].find(
		(ob: GenericSchema) => {
			return ob.identifier === IDENTIFIERS.FEED_EXPENSE;
		}
	);

	let summaryId = 'Net Accrual Feed Cost';
	for (const id in schema['showChartOfAccounts'][SECTION_CODES.CROP][
		SECTION_TYPE_CODE.ACCRUAL
	]) {
		if (
			schema['showChartOfAccounts'][SECTION_CODES.CROP][
				SECTION_TYPE_CODE.ACCRUAL
			][id][''] &&
			schema['showChartOfAccounts'][SECTION_CODES.CROP][
				SECTION_TYPE_CODE.ACCRUAL
			][id][''][''][0]['identifier'] === IDENTIFIERS.NET_ACCURAL_FEED_COST
		) {
			summaryId =
				schema['showChartOfAccounts'][SECTION_CODES.CROP][
					SECTION_TYPE_CODE.ACCRUAL
				][id][''][''][0]['generalLedgerNominalAccount'];
		}
	}
	const netAccrualFeedCost =
		schema['showChartOfAccounts'][SECTION_CODES.CROP][
			SECTION_TYPE_CODE.ACCRUAL
		][summaryId];

	feedExpense.amount = netAccrualFeedCost[''][''][0].amount;
	feedExpense.extension.measure.find((ob: GenericSchema) => {
		return ob.typeCode === STORED_MEASURE_KEYS.PER_CWT;
	}).content = netAccrualFeedCost[''][''][0].extension.measure.find(
		(ob: GenericSchema) => {
			return ob.typeCode === STORED_MEASURE_KEYS.PER_CWT;
		}
	).content;
	feedExpense.extension.measure.find((ob: GenericSchema) => {
		return ob.typeCode === STORED_MEASURE_KEYS.PER_COW_PER_YEAR;
	}).content = netAccrualFeedCost[''][''][0].extension.measure.find(
		(ob: GenericSchema) => {
			return ob.typeCode === STORED_MEASURE_KEYS.PER_COW_PER_YEAR;
		}
	).content;

	const LEVEL_1 = groupByCode(updatedDataWIthPosition, 'typeCode');
	const LEVEL_2 = {
		...LEVEL_1,
		[RM_SECTION_CODES.OPERATION_FORECAST]: groupOperationForecast(
			LEVEL_1[RM_SECTION_CODES.OPERATION_FORECAST],
			!isNew,
			copYear,
			copMonth
		),
	};
	if (!schema.journalEntry.journalEntryHeader.accountingPeriod.year) {
		schema.journalEntry.journalEntryHeader.accountingPeriod.year = String(
			Number(copYear) + 1
		);
	}

	if (isNew) {
		LEVEL_2[RM_SECTION_CODES.COP_FORECAST] = mapCOPForecast(
			schema[SECTION_TYPE_CODE.INCOME],
			schema[SECTION_TYPE_CODE.EXPENSE],
			copYear
		);
		LEVEL_2[RM_SECTION_CODES.DRP_COMP] = createDRPCompData(
			copMonth,
			Number(copYear),
			prevYearJournalEntryLine
		);
		/// once objects are created make sure they have ten cards
		LEVEL_2[RM_SECTION_CODES.DRP] = restructureDRPClassData(
			LEVEL_2[RM_SECTION_CODES.DRP],
			copMonth,
			copYear,
			prevYearJournalEntryLine
		);

		LEVEL_2[RM_SECTION_CODES.DRP_COMP] = restructureDRPCompData(
			LEVEL_2[RM_SECTION_CODES.DRP_COMP],
			copMonth,
			copYear,
			prevYearJournalEntryLine
		);
		LEVEL_2[RM_SECTION_CODES.SUMMARY] = createRMSummaryData(
			copMonth,
			Number(copYear)
		);
	} else {
		LEVEL_2[RM_SECTION_CODES.SUMMARY] = groupRMSummaryData(
			LEVEL_2[RM_SECTION_CODES.SUMMARY],
			copMonth,
			Number(copYear)
		);
		LEVEL_2[RM_SECTION_CODES.DMC] = restructureDMCData(
			LEVEL_2[RM_SECTION_CODES.DMC],
			copMonth,
			Number(copYear)
		);
		LEVEL_2[RM_SECTION_CODES.POSITION] = restructurePositionsData(
			LEVEL_2[RM_SECTION_CODES.POSITION],
			copMonth,
			Number(copYear),
			prevYearJournalEntryLine
		);

		LEVEL_2[RM_SECTION_CODES.DRP] = restructureDRPClassData(
			LEVEL_2[RM_SECTION_CODES.DRP],
			copMonth,
			copYear,
			prevYearJournalEntryLine
		);
		LEVEL_2[RM_SECTION_CODES.DRP_COMP] = restructureDRPCompData(
			LEVEL_2[RM_SECTION_CODES.DRP_COMP],
			copMonth,
			copYear,
			prevYearJournalEntryLine
		);
	}

	// find index of Mad cap data update
	const foundIndex = schema.journalEntry.journalEntryHeader.documentReference.findIndex(
		(item: any) => item.typeCode == lblMadCapLastUpdate
	);

	//console.log('foundIndex in existing:', foundIndex);
	// add map cap date if it does not exist
	if (
		foundIndex === -1 &&
		prevYearJournalEntryLine.length > 0 &&
		prevYearJournalEntryLine[0].journalEntryHeader
	) {
		// find index of mad cap data update in previous document
		const prevIndex = prevYearJournalEntryLine[0].journalEntryHeader.documentReference.findIndex(
			(item: any) => item.typeCode == lblMadCapLastUpdate
		);

		// console.log('prevIndex is :', prevIndex);

		// when date is found in previous document, add it to new document
		if (prevIndex !== -1) {
			schema.journalEntry.journalEntryHeader.documentReference.push(
				prevYearJournalEntryLine[0].journalEntryHeader.documentReference[
					prevIndex
				]
			);
		}
	}

	LEVEL_2[RM_SECTION_CODES.HEADER] = schema.journalEntry.journalEntryHeader;
	LEVEL_2['copData'] = schema['showChartOfAccounts'];
	LEVEL_2[SECTION_TYPE_CODE.INCOME] = schema[SECTION_TYPE_CODE.INCOME];
	LEVEL_2[SECTION_TYPE_CODE.EXPENSE] = schema[SECTION_TYPE_CODE.EXPENSE];

	LEVEL_2[RM_SECTION_CODES.DRP_COMP] = cleanupdrpdata(
		LEVEL_2[RM_SECTION_CODES.DRP_COMP],
		'drpcomp'
	);
	//Adding DRP-Reference and Note
	LEVEL_2[RM_SECTION_CODES.DRP_COMP] = LEVEL_2[RM_SECTION_CODES.DRP_COMP].map(
		(d: any) => {
			d.extension.distribution = d.extension.distribution.map((ed: any) => {
				if (ed.typeCode === RM_TYPECODES.ENDORSEMENT) {
					// Check for "drpReferences" key
					if (Object.keys(ed).includes('drpReferences')) {
						// Rename "drpReferences" to "dRPReferences" and retain the value
						ed.dRPReferences = ed.drpReferences;
						if (ed.dRPReferences == null) {
							ed.dRPReferences = [
								{
									typeCode: 'DRP Reference',
									identifier: '',
								},
								{
									typeCode: 'DRP Source',
									identifier: 'Manual',
								},
								{
									typeCode: 'DRP Note',
									identifier: '',
								},
							];
						}
						delete ed.drpReferences;
					} else if (!Object.keys(ed).includes('dRPReferences')) {
						// If neither "drpReferences" nor "dRPReferences" exists, add "dRPReferences"
						ed.dRPReferences = [
							{
								typeCode: 'DRP Reference',
								identifier: '',
							},
							{
								typeCode: 'DRP Source',
								identifier: 'Manual',
							},
							{
								typeCode: 'DRP Note',
								identifier: '',
							},
						];
					}
				}
				return ed;
			});
			return d;
		}
	);

	LEVEL_2[RM_SECTION_CODES.DRP] = cleanupdrpdata(
		LEVEL_2[RM_SECTION_CODES.DRP],
		'drpclass'
	);
	//Adding DRP-Reference and Note
	LEVEL_2[RM_SECTION_CODES.DRP] = LEVEL_2[RM_SECTION_CODES.DRP].map(
		(d: any) => {
			d.extension.distribution = d.extension.distribution.map((ed: any) => {
				if (ed.typeCode === RM_TYPECODES.ENDORSEMENT) {
					// Check for "drpReferences" key
					if (Object.keys(ed).includes('drpReferences')) {
						// Rename "drpReferences" to "dRPReferences" and retain the value
						ed.dRPReferences = ed.drpReferences;
						if (ed.dRPReferences == null) {
							ed.dRPReferences = [
								{
									typeCode: 'DRP Reference',
									identifier: '',
								},
								{
									typeCode: 'DRP Source',
									identifier: 'Manual',
								},
								{
									typeCode: 'DRP Note',
									identifier: '',
								},
							];
						}
						delete ed.drpReferences;
					} else if (!Object.keys(ed).includes('dRPReferences')) {
						// If neither "drpReferences" nor "dRPReferences" exists, add "dRPReferences"
						ed.dRPReferences = [
							{
								typeCode: 'DRP Reference',
								identifier: '',
							},
							{
								typeCode: 'DRP Source',
								identifier: 'Manual',
							},
							{
								typeCode: 'DRP Note',
								identifier: '',
							},
						];
					}
				}
				return ed;
			});
			return d;
		}
	);

	return updateRefrence(LEVEL_2);
};

export const formatSchema = (
	schema: GenericSchema,
	basicInfo: GenericSchema | null = null,
	prevYearJournalEntryLine: Array<GenericSchema> = []
) => {
	const docIdentifier = schema?.showChartOfAccounts?.versionIdentifier;
	const data = schema?.showChartOfAccounts?.dataArea?.chartOfAccounts;
	// mapped values placholder
	if (data && !basicInfo) {
		data.forEach((item: GenericSchema) => {
			item.reportingSection = item?.extension.code.find((ob: GenericSchema) => {
				return ob.typeCode === COA_TYPECODES.REPORTING_SECTION;
			})?.content;
			item.extTypeCode = item?.extension?.typeCode;
			item.amount = 0;
			if (!basicInfo) {
				(item.note = ''),
					(item.extension.amount = [
						{ content: 0, typeCode: STORED_AMT_KEYS.BEGINNING_BALANCE },
						{ content: 0, typeCode: STORED_AMT_KEYS.ENDING_BALANCE },
						{ content: 0, typeCode: STORED_AMT_KEYS.VALUE_PER_HEAD },
						{ content: 0, typeCode: STORED_AMT_KEYS.BEGINNING_NO_OF_HEAD },
						{ content: 0, typeCode: STORED_AMT_KEYS.ENDING_NO_OF_HEAD },
						{ content: 0, typeCode: STORED_AMT_KEYS.ASSIGNED_PERCENTAGE },
					]);
				item.extension.measure = [
					{ content: 0, typeCode: STORED_MEASURE_KEYS.PER_CWT },
					{ content: 0, typeCode: STORED_MEASURE_KEYS.PER_COW_PER_DAY },
					{ content: 0, typeCode: STORED_MEASURE_KEYS.PER_COW_PER_YEAR },
					{ content: 0, typeCode: STORED_MEASURE_KEYS.PER_ACRE },
				];
			}
		});
	} else if (data && schema?.journalEntry?.typeCode === SCHEMA_TYPE.RM) {
		data.forEach((item: GenericSchema) => {
			item.reportingSection = item?.extension.code.find((ob: GenericSchema) => {
				return ob.typeCode === COA_TYPECODES.REPORTING_SECTION;
			})?.content;
			item.extTypeCode = item?.extension?.typeCode;
		});
	}

	if (
		!schema.journalEntry ||
		(schema?.journalEntry?.typeCode === SCHEMA_TYPE.RM && data) ||
		schema?.journalEntry?.typeCode !== SCHEMA_TYPE.RM
	) {
		// Grouping COA section
		const LEVEL_1: GenericSchema = groupByCode(data, 'reportingSection');
		let LEVEL_2: GenericSchema = {};
		let LEVEL_3: GenericSchema = {};
		let LEVEL_4: GenericSchema = {};
		let LEVEL_5: GenericSchema = {};
		for (const property in LEVEL_1) {
			LEVEL_1[property] = sortBySequence(LEVEL_1[property]);
			LEVEL_2[property] = groupByCode(LEVEL_1[property], 'typeCode');

			for (const property1 in LEVEL_2[property]) {
				LEVEL_2[property][property1] = sortBySequence(
					LEVEL_2[property][property1]
				);

				LEVEL_3[property] = LEVEL_3[property] ? LEVEL_3[property] : {};
				LEVEL_3[property][property1] = groupByCode(
					LEVEL_2[property][property1],
					'generalLedgerNominalAccount'
				);

				for (const property2 in LEVEL_3[property][property1]) {
					LEVEL_3[property][property1][property2] = sortBySequence(
						LEVEL_3[property][property1][property2]
					);

					LEVEL_4[property] = LEVEL_4[property]
						? LEVEL_4[property]
						: LEVEL_3[property];
					LEVEL_4[property][property1][property2] = groupByCode(
						LEVEL_3[property][property1][property2],
						'accountTypeCode'
					);

					for (const property3 in LEVEL_4[property][property1][property2]) {
						LEVEL_4[property][property1][property2][property3] = sortBySequence(
							LEVEL_4[property][property1][property2][property3]
						);

						LEVEL_5[property] = LEVEL_5[property]
							? LEVEL_5[property]
							: LEVEL_4[property];
						LEVEL_5[property][property1][property2][property3] = groupByCode(
							LEVEL_3[property][property1][property2][property3],
							'extTypeCode'
						);

						for (const property4 in LEVEL_5[property][property1][property2][
							property3
						]) {
							LEVEL_5[property][property1][property2][property3][
								property4
							] = sortBySequence(
								LEVEL_5[property][property1][property2][property3][property4]
							);
						}
					}
				}
			}
		}
		// mapped values placholder for header section
		LEVEL_4[SECTION_CODES.BASIC_INFO] = basicInfo
			? basicInfo
			: {
					id: '',
					identifier: 0,
					documentDateTime: null,
					revisionIdentifier: 0,
					documentReference: {
						identifier: docIdentifier,
					},
					status: { code: '' },
					note: '',
					variationIdentifier: 'Standard',
					description: '',
					accountingPeriod: {
						periodIdentifier: 'January',
						year: 0,
						effectiveTimePeriod: { duration: 12 },
					},
					party: { identifier: '', name: '', location: { identifier: 0 } },
					extension: {
						quantity: [
							{
								content: 0,
								typeCode: JOURNAL_HEADER_KEYS.AVG_NO_OF_MATURE_COWS,
							},
							{
								content: 0,
								typeCode: JOURNAL_HEADER_KEYS.AVG_NO_OF_REPLACEMENT_INVENTORY,
							},
							{
								content: 0,
								typeCode: JOURNAL_HEADER_KEYS.VOL_OF_MILK_SOLD_CWTS,
							},
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.CORN_FORAGE_ACRES },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.CORN_GRAIN_ACRES },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.ALFA_ALFA_ACRES },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.OTHER_FORAGE_ACRES },
							{
								content: 0,
								typeCode: JOURNAL_HEADER_KEYS.OTHER_CASH_GRAIN_ACRES,
							},
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.TOTAL_ACRES },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.TOTAL_CALVES },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.TOTAL_HEIFERS },
							{ content: 0, typeCode: JOURNAL_HEADER_KEYS.TOTAL_BRED_HEIFERS },
						],
					},
			  };

		if (schema?.journalEntry?.typeCode === SCHEMA_TYPE.RM) {
			schema['showChartOfAccounts'] = updateRefrence(LEVEL_4);

			return formatRMSchema(
				schema,
				basicInfo?.accountingPeriod.year,
				basicInfo?.accountingPeriod.periodIdentifier,
				prevYearJournalEntryLine
			);
		}

		return updateRefrence(LEVEL_4);
	}

	return formatRMSchema(
		schema,
		basicInfo?.accountingPeriod.year,
		basicInfo?.accountingPeriod.periodIdentifier,
		prevYearJournalEntryLine
	);
};

const sortBySequence = (arr: Array<GenericSchema>) => {
	return _.sortBy(arr, item => item?.extension?.sequenceNumber);
};

const groupByCode = (arr: Array<GenericSchema>, code: string) => {
	return _.groupBy(arr, code);
};

export default reducer;
