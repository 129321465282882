import { getEnvoirment } from '../utils';

export enum ENVOIRMENT {
	DEV = 'DEV',
	QA = 'QA',
	LOCAL = 'LOCAL',
	PROD = 'PROD',
}
const API_BASE_PATH = () => {
	const ENV = getEnvoirment();
	let url = '';
	if (ENV === ENVOIRMENT.PROD) {
		url = 'https://api.digitalag.farm/dairy';
	} else if (ENV === ENVOIRMENT.QA) {
		url = 'https://apiqa.digitalag.farm/dairy';
	} else if (ENV === ENVOIRMENT.DEV) {
		url = 'https://apidv.digitalag.farm/dairy';
	} else {
		url = 'https://apidv.digitalag.farm/dairy';
	}

	return url;
};

const API_PARTIAL_COP = () => '/finance/v1/cost-of-production/api';
const API_PARTIAL_RM = () => '/finance/v1/risk-margin-insight/api';
const API_PARTIAL_AUTH = () => '/security/v1/userAuth/api';
const API_PARTIAL_WEB = () => '/account/v1/party/api';
const API_AGENCY_DRP = () => '/finance/v1/drpapi/api/getagencies';

const LOGOUT_REDIRECT_URI = () => {
	const ENV = getEnvoirment();
	let url = '';
	if (ENV === ENVOIRMENT.PROD) {
		url = 'https://dairyrmi.com';
	} else if (ENV === ENVOIRMENT.QA) {
		url = 'https://webapp-cp-pp-qa.azurewebsites.net';
	} else if (ENV === ENVOIRMENT.DEV) {
		url = 'https://webapp-cp-pp-dv.azurewebsites.net';
	} else {
		url = 'http://localhost:3000';
	}
	return url;
};
const SSO_BASE_PATH = () => {
	const ENV = getEnvoirment();
	let url = '';
	if (ENV === ENVOIRMENT.PROD) {
		url = 'https://sso.landolakesinc.com';
	} else if (ENV === ENVOIRMENT.QA) {
		url = 'https://ssoqa.landolakesinc.com';
	} else if (ENV === ENVOIRMENT.DEV) {
		url = 'https://ssoqa.landolakesinc.com';
	} else {
		url = 'https://ssoqa.landolakesinc.com';
	}
	return url;
};
const AUTH_API_BASE_PATH: string = '';

const DB_NAME: string = 'PRODUCER_PROFITABILITY';
const TB_PRODUCER: string = 'producer';
const DB_VERSION: number = 1;

const USER_INFO: string = 'ui';
const AUTH_TOKEN: string = 'at';
const AUTH_EXPIRATION: string = 'ae';
const REFRESH_TOKEN: string = 'rt';

const DEFAULT_EXPIRATION_SEC: number = 30 * 60;
const DEFAULT_LOCALE: string = 'en-US';

const REGEX_VALUES = {
	POSITIVE_INT: '^[0-9,]+$',
	INT: '^[+-]?[0-9]+$',
	INT_1_TO_12: '(^0?[1-9,]$)|(^1[0-2]$)',
	POSITIVE_DECIMALS: '^[0-9,]*[/.]{0,1}[0-9]+$',
	DECIMAL_ONE_PLACE: '^[+-]?[0-9,]*[/.]{0,1}[0-9]{0,1}$',
	DECIMAL_TWO_PLACES: '^[+-]?[0-9,]*[/.]{0,1}[0-9]{0,2}$',
	DECIMAL_THREE_PLACES: '^[+-]?[0-9,]*[/.]{0,1}[0-9]{0,3}$',
	DECIMAL_FOUR_PLACES: '^[+-]?[0-9,]*[/.]{0,1}[0-9]{0,4}$',
	DECIMAL_PERCENTAGE:
		'(^[+]?[0]*[0-9]{0,2}[/.][0-9]{1,2}$)|(^[+]?[0]*[0-9]{1,2}$)|(^[1][0][0]$)',
	DECIMAL_TWO_PLACES_BEFORE:
		'(^[+-]?[0]*[0-9]{0,2}[/.][0-9]{1,2}$)|(^[+-]?[0]*[0-9]{1,2}$)|(^[0]+$)|(^[+-])',
	DECIMAL_THREE_PLACES_BEFORE:
		'(^[+-]?[0]*[0-9]{0,3}[/.][0-9]{1,2}$)|(^[+-]?[0]*[0-9]{1,3}$)|(^[0]+$)|(^[+-])',
	DECIMAL_THREE_PLACES_BEFORE_POSITIVE:
		'(^[+]?[0]*[0-9]{0,3}[/.][0-9]{1,2}$)|(^[+]?[0]*[0-9]{1,3}$)|(^[0]+$)|(^[+])',
	DECIMAL_THREE_PLACES_BEFORE_THREE_PLACE_AFTER_POSITIVE:
		'(^[+]?[0]*[0-9]{0,3}[/.][0-9]{1,3}$)|(^[+]?[0]*[0-9]{1,3}$)|(^[0]+$)|(^[+])',
	DECIMAL_THREE_PLACES_BEFORE_THREE_PLACE_AFTER_NEGATIVE:
		'(^[-][0-9]{0,3}[/.][0-9]{1,3}$)|(^[-][0-9]{1,3}$)|(^[0]+$)|(^[+])',
	DECIMAL_TWO_PLACES_BEFORE_THREE_PLACES_AFTER:
		'(^[0]*[0-9]{0,2}[/.][0-9]{1,3}$)|(^[+-]?[0]*[0-9]{1,2}$)|(^[0]+$)|(^[+-])/g',
	POSITIVE_DECIMAL_THREE_PLACES_AFTER:
		'(^[0]*[0-9]*[/.][0-9]{1,3}$)|(^[+]?[0]*[0-9]*$)|(^[0]+$)|(^[+])/g',
	POSITIVE_DECIMAL_FIVE_PLACES_AFTER:
		'(^[0]*[0-9]*[/.][0-9]{1,5}$)|(^[+]?[0]*[0-9]*$)|(^[0]+$)|(^[+])/g',
	DECIMAL_TWO_PLACES_BEFORE_FOUR_PLACES_AFTER:
		'(^[0]*[0-9]{0,2}[/.][0-9]{1,4}$)|(^[+-]?[0]*[0-9]{1,2}$)|(^[0]+$)|(^[+-])',

	DECIMAL_THREE_PLACES_BEFORE_FOUR_PLACES_AFTER:
		'(^[0]*[0-9]{0,3}[/.][0-9]{1,4}$)|(^[+-]?[0]*[0-9]{1,3}$)|(^[0]+$)|(^[+-])',
	ALL: '(.*?)',
	NEGATIVE_ALLOWED: '^[+-]?[0-9,]*[/.]{0,1}[0-9]+$',
	ONLY_NEGATIVE: '(^[-][0-9,]{0,3}[/.][0-9]{1,2}$)|(^[-][0-9]{1,3}$)|(^[0]+$)',
	THREENumeric_DOT_TwoNumeric: /[^(\d{0:3}.{0,1}?\d{0,2}?)]/g,
	FourNumeric: /[0-9]{4}/g,
	PrecedingZero: /^0+/g,
	Month_Year_Symbol: /(@?[a-zA-Z0-9]+)_(\d+_\w+)_/,
	ALPHA_NUMERIC_SPL_CHAR: /[,+$~%'":*?<>{}@=!`^;]/g,
};

export enum ROLES {
	NONE = 'None',
	VIEW = 'View',
	UPDATE = 'Update',
}

export enum MSG_TYPE {
	SUCCESS = 'success',
	ERROR = 'error',
}

const MENU_STATE = {
	expand: 1,
	collpase: 0,
};

const VERSION_STATUS = {
	ARCHIVED: 'ARCHIVED',
	DRAFT: 'DRAFT',
	FINAL: 'FINAL',
};

const SCHEMA_TYPE = {
	COP: 'COP',
	RM: 'RM',
};

const MONTHS = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
const FULL_MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const START_YEAR = 2016;

const PRINT_FOOTER_TEXT = `This analysis was prepared using data provided by a Land O'Lakes
member, and its use is the sole responsibility of the member. Land
O'Lakes does not guarantee the accuracy of this analysis, and
makes no warranties as to its usefulness or predictive value. Land
O' Lakes is not responsible, and has no liability, for the
outcomes of decisions using this information or analysis, or any
related recommendations.This Report is subject to the RMI License
Agreement.`;

export {
	API_AGENCY_DRP,
	API_BASE_PATH,
	API_PARTIAL_AUTH,
	API_PARTIAL_COP,
	API_PARTIAL_RM,
	API_PARTIAL_WEB,
	AUTH_API_BASE_PATH,
	AUTH_EXPIRATION,
	AUTH_TOKEN,
	DB_NAME,
	DB_VERSION,
	DEFAULT_EXPIRATION_SEC,
	DEFAULT_LOCALE,
	FULL_MONTHS,
	LOGOUT_REDIRECT_URI,
	MENU_STATE,
	MONTHS,
	PRINT_FOOTER_TEXT,
	REFRESH_TOKEN,
	REGEX_VALUES,
	SCHEMA_TYPE,
	SSO_BASE_PATH,
	START_YEAR,
	TB_PRODUCER,
	USER_INFO,
	VERSION_STATUS,
};
