const NUM_OF_CARDS = 24;
const RM_NAVIGATION_STEPS = [
	{
		id: 'herd-production',
		name: 'FORECAST INFORMATION',
		completed: false,
		substeps: [
			{
				id: 'herd-production',
				name: 'Herd Production',
				completed: false,
			},
			{
				id: 'rev-exp',
				name: 'Revenue and Expense',
				completed: false,
			},
		],
	},
	{
		id: 'class3',
		name: 'RISK MANAGEMENT TOOLS',
		completed: false,
		substeps: [
			{
				id: 'class3',
				name: 'Class 3',
				completed: false,
			},
			{
				id: 'class4',
				name: 'Class 4',
				completed: false,
			},
			{
				id: 'cheese',
				name: 'Cheese',
				completed: false,
			},
			{
				id: 'dmc',
				name: 'DMC',
				completed: false,
			},
			{
				id: 'drp',
				name: 'DRP - CLASS',
				completed: false,
			},
			{
				id: 'drp-comp',
				name: 'DRP - COMPONENT',
				completed: false,
			},
		],
	},
	{
		id: 'rm-summary',
		name: 'MARKETING PLAN SUMMARY',
		completed: false,
		substeps: [],
	},
];

const RM_OPTIONS = {
	CONTRACT: 'Contract',
	FLOOR: 'Floor',
	COLLAR: 'Collar',
	CEILING: 'Ceiling',
};

const RM_SCHEMA_CODE = 'RM_SCHEMA';
const RM_ALLOCATION_TYPES = {
	DIRECT_ENTRY: 'Direct Entry',
	FIXED: 'Fixed',
	VAR_COW: 'Var-Cow',
	VAR_CWT: 'Var-Cwt',
	FORECAST_SUMMARY: 'Forecast Summary',
	DO_NOT_FORECAST: 'Do Not Forecast',
};

const Comm_SymbolTo_Volume_Dict: { [id: string]: number } = {
	'@DA': 200000,
	'@DK': 200000,
	'@CSC': 20000,
};

const Comm_Divisor_Dict: { [id: string]: number } = {
	'@DA': 100,
	'@DK': 100,
	'@CSC': 1,
};

const Comm_Multiplier_Dict: { [id: string]: number } = {
	'@DA': 2000,
	'@DK': 2000,
	'@CSC': 20000,
};

const RM_COMMODITIES = {
	CLASS3: {
		COMMODITY: 'CLASS3',
		MUTLIPLIER: 200000,
		SYMBOL: '@DA',
	},
	CLASS4: {
		COMMODITY: 'CLASS4',
		MUTLIPLIER: 200000,
		SYMBOL: '@DK',
	},
	CHEESE: {
		COMMODITY: 'CHEESE',
		MUTLIPLIER: 20000,
		SYMBOL: '@CSC',
	},
	CORN: {
		COMMODITY: 'CORN',
		MUTLIPLIER: 0,
		SYMBOL: '@C',
	},
	SBM: {
		COMMODITY: 'SBM',
		MUTLIPLIER: 0,
		SYMBOL: '@SM',
	},
	ALFALFA: {
		COMMODITY: 'Alfalfa',
		MULTIPLIER: 0,
		SYMBOL: '@ALFALFA',
	},
	ACTUALFEED: {
		COMMODITY: 'ACTUAL FEED',
		MULTIPLIER: 0,
		SYMBOL: '@ACTUALFEED',
	},
	ALLMILK: {
		COMMODITY: 'ALL MILK',
		MULTIPLIER: 0,
		SYMBOL: '@ALLMILK',
	},
};

const RM_COMMODITIES_INPUT = {
	STRIKE: 'Strike',
	CONTRACTS: 'Number of Contracts',
	VOLUME: 'Volume',
	PREMIUM: 'Premium',
	NET: 'Net',
	ADMINFEE: 'Admin Fee',
	MTM: 'MTM',
	VOLUME_TOTAL: 'Volume Total',
	VOLUME_FILLED: 'Volume Filled',
	VOLUME_WORKING: 'Volume Working',
};

const RM_SECTION_CODES = {
	HEADER: 'Header',
	OPERATION_FORECAST: 'Operation Forecast',
	COP_DATA: 'copData',
	POSITION: 'Position',
	COP_FORECAST: 'COP Forecast',
	DRP_COMP: 'DRP Comp',
	DMC: 'DMC',
	DRP: 'DRP Class',
	SUMMARY: 'Summary',
};

const RM_OPERATING_FORECAST_SECTION_CODES = {
	MONTHS: 'months',
	QUARTERS: 'quarters',
	YEAR: 'year',
};

const RM_TYPECODES = {
	ENDORSEMENT: 'Endorsement',
	ENDORSEMENT_SUMMARY: 'Endorsement Summary',
	QUARTERLY_ENDORSEMENT_SUMMARY: 'Quarterly Summary',
	ENDORSEMENT_DATE: 'Endorsement Date',
	DECLARED_PROD_QTR_END: 'Declared Production/QTR',
	CLASS_4_FLOOR_PRICE: 'Floor Price Class IV',
	CLASS_3_FLOOR_PRICE: 'Floor Price Class III',
	PERCENT_COVERED: 'Percent of milk covered',
	PROTECT_FACT: 'Protection Factor/QTR (number)',
	YIELD_ADJ: 'Yield Adjustment Factor Ratio',
	PREMIUM_DOLLAR: 'Premium Dollar',
	// GROSS_EXPECTED_CWT: 'Gross Expected Revenue Guarantee ($/cwt)',
	GROSS_EXPECTED_TOTAL: 'Gross Expected Revenue Guarantee (Total$)',
	// GROS_PROB_CWT: 'Gross Probable Indemnity ($/cwt)',
	GROS_PROB_TOTAL: 'Gross Probable Indemnity (Total$)',
	// MTM_ADJ_CWT: 'MTM w/YF Adjustment ($/cwt)',
	MTM_ADJ_TOTAL: 'MTM w/YF Adjustment (Total$)',
	// BEG_CWT: 'Beginning Profitable Level ($/cwt)',
	BEG_TOTAL: 'Beginning Profitable Level (Total$)',
	// MARKET_PRICE_CWT: 'Market Price Weighted ($/cwt)',
	MARKET_PRICE_TOTAL: 'Market Price Weighted (Total$)',
	// NET_MTM_CWT: 'Market to Market ($/cwt)',
	NET_MTM_TOTAL: 'Market to Market (Total$)',
	COP_VERSION: 'CoP Version',
	LAST_MODIFIED: 'Modified At',
	FORECAST_NOTES: 'Forecast Notes',
	RM_NOTES: 'RM Plan Notes',
	LACTATING_COWS: 'Lactating Cows',
	MILK_PER_COW_PER_DAY: 'Milk per Cow per Day',
	CWT_PER_MONTH: 'CWTS per Month',
	MILK$: 'Forecast Milk income',
	AVG_PC_HERD_DRY: 'Annual Average pct Dry Heard',
	AVG_HERD_SIZE: 'Estimated Average Herd Size',
	Q1_EST_PROD_FACTOR: 'Q1 Estimated Production Factor',
	Q2_EST_PROD_FACTOR: 'Q2 Estimated Production Factor',
	Q3_EST_PROD_FACTOR: 'Q3 Estimated Production Factor',
	Q4_EST_PROD_FACTOR: 'Q4 Estimated Production Factor',
	COMMODITY: 'Commodity',
	CLASS_3: 'Class 3',
	MTM_FUTURES_POSITION: 'Net Futures Position',
	SUMMARY: 'Summary',
	POSITION: 'Position',
	STRIKE: 'Strike',
	CONTRACTS: 'Number of Contracts',
	VOLUME: 'Volume',
	PREMIUM: 'Premium',
	NET: 'Net',
	ADMIN: 'Admin Fee',
	DEFAULT_ALLOCATION_TYPE: 'Default Allocation Type',
	SETTLEMENT_PRICE_AMT: 'settlementPriceAmount',
	FORECAST_AMOUNT: 'Forecast Amount',
	FORECAST_PER_CWT: 'Forecast Per CWT',
	FORECAST_PER_COW_PER_YEAR: 'Forecast Per Cow Per year',
	AMOUNT: 'Amount',
	LBS_ANNUALY: 'Lbs Covered Annually (Lbs)',
	MARGIN_FLOOR: 'Margin Floor Cover (dol per CWT)',
	MARGIN_FLOOR_PREMIUM: 'Margin Floor Premium (dol per CWT)',
	DMC: 'DMC',
	DRP: 'DRP Class',
	ACTUAL_FORECASTED_PRICES: 'Actual/ Forecasted Prices',
	BUTTERFAT_$_CWT: 'Butterfat ($/cwt)',
	PROTEIN_$_CWT: 'Protein ($/cwt)',
	NON_FAT_SOLIDS_$_LBS: 'Nonfat Solids ($/lb)',
	OTHER_SOLIDS_$_CWT: 'Other Solids ($/cwt)',
	DECLARED_TESTS: 'Declared Tests',
	BUTTERFAT_LBS_CWT: 'Butterfat (lbs/cwt)',
	PROTEIN_LBS_CWT: 'Protein (lbs/cwt)',
	OTHER_SOLIDS_FIXED_LBS_CWT: 'Other Solids (Fixed) (lbs/cwt)',
	INPUTS: 'Inputs',
	DECLARED_PROD_QTR: 'Declared Production/QTR (Lbs)',
	DECLARED_PROD_MONTH: 'Declared Production/MO (Lbs)',
	BUTTERFAT_QUOTE: 'Butterfat Quote ($/cwt)',
	PROTEIN_QUOTE: 'Protein Quote ($/cwt)',
	OTHER_SOLIDS_QUOTE: 'Other Solids Quote ($/cwt)',
	NON_FAT_SOLIDS_QUOTE: 'Nonfat Solids Quote ($/lb)',
	COMPONENT_WEIGHTING_FACTOR: 'Component Weighting Factor',
	NON_FAT_BUTTERFAT_WEIGHTING_FACTOR: 'Nonfat/Butterfat Weighting Factor',
	TOTAL_PRICE: 'Total Price ($/cwt)',
	PERCENT_MILK_COVERED: 'Percent of Milk Covered (%)',
	PROTECTION_FACTOR_QTR: 'Protection Factor/QTR (number)',
	PREMIUM_PAID: 'Premium Paid ($/cwt)',
	EXPECTED_OUTPUTS: 'Expected Outputs',
	EXPECTED_REVENUE_GUARANTEE: 'Expected Revenue Guarantee ($/cwt)',
	BEGIN_PROFITABLE_LEVEL: 'Beginning Profitable Level ($/cwt)',
	MARKET_PRICE_WEIGHTED: 'Market Price Weighted ($/cwt)',
	MTM_PER_CWT: 'MTM ($/cwt)',
	MTM_PER_MONTH: 'MTM/MO (Total$)',
	MTM_PER_QTR: 'MTM/QTR (Total$)',
	ACTUAL_OUTPUTS: 'Actual Outputs',
	YF_RATIO: 'Yield Adjustment Factor Ratio (YF)',
	PROBABLE_INDEMNITY: 'Probable Indemnity',
	MTM_YF_ADJUSTMENTS: 'MTM w/YF Adjustment ($/cwt)',
	CORN: 'Corn ($/Bu)',
	SBM: 'SBM ($/Ton)',
	ALFALFA: 'Alfalfa/Hay ($/Ton)',
	PROJECTED_FEED_TOTAL: 'Projected Feed Total ($/CWT)',
	ACTUAL_FEED_TOTAL: 'Actual Feed Total ($/CWT)',
	ALL_MILK: 'All Milk ($/CWT)',
	MARGIN_OVER_FEED: 'Margin Over Feed ($/CWT)',
	DMC_IDEMNITY: 'DMC Indemnity ($/CWT)',
	INDEMNITY_PAYMENT: 'Indemnity Payment ($/Month)',
	NET_PAYMENT: 'Net Payment (-Floor Prem) ($/Month)',
	CUMMULATIVE_TOTAL_RETURN: 'Cummulative Net Total Return ($)',
	QUOTE_TYPE: 'Quote Type',
	DMC_AMOUNT: 'DMC Amount',
	CME_PRICE: 'CME Prices',
	CLASS3_PRICE: 'Class 3 Price',
	CLASS4_PRICE: 'Class 4 Price',
	DECLARED_PRODUCTION_QTR: 'Declared Production/QTR',
	DECLARED_PRODUCTION_MO: 'Declared Production/MO',
	FLOOR_PRICE_CLASS3: 'Floor Price Class III',
	FLOOR_PRICE_CLASS4: 'Floor Price Class IV',
	PERCENT_MILK_COV: 'Percent of milk covered',
	CLASS3_WEIGHT_FACTOR: 'Class 3 weight factor',
	CLASS4_WEIGHT_FACTOR: 'Class 4 weight factor',
	PREM_PAID: 'Premium Paid',
	EXPECTED_REVENUE_GUARANTEE_POSITION: 'Expected Revenue Guarantee',
	BEGINNING_PROFITABLE_LEVEL: 'Beginning Profitable Level',
	MARKET_PRICE_WEIGHT: 'Market Price Weighted',
	MTM: 'MTM',
	MTM_MO: 'MTM/MO',
	MTM_QTR: 'MTM/QTR',
	YIELD_ADJ_FACTOR_RATIO: 'Yield Adjustment Factor Ratio',
	MTM_ADJ: 'MTM w/YF Adjustment',
	PROD_COVERAGE: 'Production / Coverage',
	PERCENT_COVERAGE: '% Coverage',
	POSITION_COST: 'Position Cost',
	NET_POSITION_UPLIFT_DRAG: 'Net Position Uplift/Drag',
	FORECAST_MILK_VOLUME_LBS: 'Forecast Milk Volume (Lbs)',
	CLASS_4: 'Class 4',
	CHEESE: 'Cheese',
	DRP_CLASS: 'DRP-Class',
	DRP_COMPONENT: 'DRP Component',
	NET_OPEN_MILK: 'Net Open Milk',
	NET_OPEN_MILK_WO_DRP: 'Net Open Milk w/o DRP',
	TOTAL_POSITION_COST: 'Total Position Cost',
	COST_PER_CWT_COVERED: 'Cost/Cwt (Covered)',
	COST_PER_CWT_EST_PRODUCTION: 'Cost/Cwt (Tot. Est Production)',
	NET_UPLIFT_DRAG: 'Net Uplift/(Drag)',
	NET_UPLIFT_DRAG_PER_CWT_COVERED: 'Net Uplift (Drag)/Cwt (Covered)',
	NET_UPLIFT_DRAG_PER_CWT_EST_PRODUCTION:
		'Net Uplift (Drag)/Cwt ,(Tot. Est Production)',
	FORECASTED_NET_MILK_REVENUE: 'Forecasted Net Milk Revenue',
	ACCRUAL: 'Accrual',
	ESTIMATED_NET_INCOME_CWT: 'Estimated Net Income /Cwt',
	ESTIMATED_NET_INCOME_TOTAL: 'Estimated Net Income Total',
	CHEESE_WORKING: 'Cheese Working',
	CLASS3_WORKING: 'Class 3 Working',
	CLASS4_WORKING: 'Class 4 Working',
	NET_OPEN_MILK_INC_WORKING: 'Net Open Milk including working',
	NET_OPEN_MILK_WO_DRP_WORKING: 'Net Open Milk w/o DRP including working',
	VOLUME_MANUAL_FILLED: 'Volume Filled',
	VOLUME_WORKING: 'Volume Working',
	TOTAL_COVERED_PRODUCTION_CLASS3: 'Class 3 Total Covered Production',
	TOTAL_COVERED_PRODUCTION_CLASS4: 'Class 4 Total Covered Production',
	TOTAL_COVERED_PRODUCTION_MO: 'Total Covered Production/Mo',
	TOTAL_COVERED_PRODUCTION_QTR: 'Total Covered Production/QTR',
	WEIGHT_AVERAGE_YIELD_ADJUSTMENT_FACTOR:
		'Weight Average Yield Adjustment Factor',
	WEIGHT_AVERAGE_PROTECTION_FACTOR: 'Weight Average Protection Factor',
	GROSS_EXPECTED_REVENUE_GUARANTEE_CWT:
		'Gross Expected Revenue Guarantee ($/cwt)',
	BEGINNING_PROFITABLE_LEVEL_CWT: 'Beginning Profitable Level ($/cwt)',
	MARKET_PRICE_WEIGHTED_CWT: 'Market Price Weighted ($/cwt)',
	MARKET_TO_MARKET_CWT: 'Market to Market ($/cwt)',
	GROSS_PROBABLE_INDEMNITY_CWT: 'Gross Probable Indemnity ($/cwt)',
	MTM_WYF_ADJUSTMENT_CWT: 'MTM w/YF Adjustment ($/cwt)',
	GROSS_EXPECTED_REVENUE_GUARANTEE_MO_TOTAL:
		'Gross Expected Revenue Guarantee/Mo (Total$)',
	BEGINNING_PROFITABLE_LEVEL_MO_TOTAL: 'Beginning Profitable Level/Mo (Total$)',
	MARKET_PRICE_WEIGHTED_MO_TOTAL: 'Market Price Weighted/Mo (Total$)',
	GROSS_PROBABLE_INDEMNITY_MO_TOTAL: 'Gross Probable Indemnity/Mo (Total$)',
	MARKET_TO_MARKET_MO_TOTAL: 'Market to Market/Mo (Total$)',
	GROSS_EXPECTED_REVENUE_GUARANTEE_QTR_TOTAL:
		'Gross Expected Revenue Guarantee/QTR (Total$)',
	BEGINNING_PROFITABLE_LEVEL_QTR_TOTAL:
		'Beginning Profitable Level/QTR (Total$)',
	GROSS_PROBABLE_INDEMNITY_QTR_TOTAL: 'Gross Probable Indemnity/QTR (Total$)',
	MARKET_PRICE_WEIGHTED_QTR_TOTAL: 'Market Price Weighted/QTR (Total$)',
	MARKET_TO_MARKET_QTR_TOTAL: 'Market to Market/QTR (Total$)',
	MTM_WYF_ADJUSTMENT_QTR_TOTAL: 'MTM w/YF Adjustment/QTR (Total$)',
	MTM_WYF_ADJUSTMENT_MO_TOTAL: 'MTM w/YF Adjustment/Mo (Total$)',
	DATA_TIME: 'dateTime',
	DRP_REFERENCE: 'DRP Reference',
	DRP_NOTE: 'DRP Note',
};

const COMMODITY_STRUCTURE = {
	TYPECODE: 'typeCode',
	EXTENSION: 'extension',
};
const COMMODITY_EXTENSION = {
	CODE: 'code',
	DISTRIBUTION: 'distribution',
	ACCOUNTING_PERIOD: 'accountingPeriod',
};

const COMMODITY_SUMMARY = {
	HEDGE_YIELD: 'Net Contract / Hedge Yield',
	CASH_YIELD: 'Fcst Volume / Cash Yield',
	NET_EXPOSURE: 'Net Exposure',

	CL3_BREAKEVEN: 'CL3 Breakeven',
	CL4_BREAKEVEN: 'CL4 Breakeven',
	CHEESE_BREAKEVEN: 'CHEESE Breakeven',

	CONTRACT_SETTLE: 'CME Contract Quote/Settle',

	CL3_MILK_REVENUE: 'Net Realized CL3/Milk Revenue',
	CL4_MILK_REVENUE: 'Net Realized CL4/Milk Revenue',
	CHEESE_MILK_REVENUE: 'Net Realized CHEESE/Milk Revenue',

	NET_MARIGIN: 'Net Margin',
};

const NET_COP_BE_MILK_ID = '91';
const CLASS3_BREAKEVEN_ID = '92';
const CLASS4_BREAKEVEN_ID = '93';

export {
	CLASS3_BREAKEVEN_ID,
	CLASS4_BREAKEVEN_ID,
	COMMODITY_EXTENSION,
	COMMODITY_STRUCTURE,
	COMMODITY_SUMMARY,
	Comm_Divisor_Dict,
	Comm_Multiplier_Dict,
	Comm_SymbolTo_Volume_Dict,
	NET_COP_BE_MILK_ID,
	NUM_OF_CARDS,
	RM_ALLOCATION_TYPES,
	RM_COMMODITIES,
	RM_COMMODITIES_INPUT,
	RM_NAVIGATION_STEPS,
	RM_OPERATING_FORECAST_SECTION_CODES,
	RM_OPTIONS,
	RM_SCHEMA_CODE,
	RM_SECTION_CODES,
	RM_TYPECODES,
};
