import { STORE_DRP_DATA } from '../actions/actions';

const initialState = {
	drpData: [],
	// other states...
};

const drpReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case STORE_DRP_DATA: {
			// Wrapping the code inside a block scope
			const uniqueDrpData = action.payload.reduce((acc, item) => {
				if (
					!acc.find(
						existingItem => existingItem.drpReference === item.drpReference
					)
				) {
					acc.push(item);
				}
				return acc;
			}, []);
			// console.log('statedata', state.drpData);
			// console.log('uniqueDrpData:', uniqueDrpData);
			return {
				...state,
				drpData: uniqueDrpData,
			};
		}

		// other cases...
		default:
			return state;
	}
};

export default drpReducer;
