import { updateObject } from '../../common/utils/utility';
import { ActionSchema, MessageNotification } from '../../schemas';
import {
	HIDE_MESSAGE,
	SHOW_MESSAGE,
	SHOW_MULTI_MESSAGE,
	START_MADCAP_LOADER,
	STOP_MADCAP_LOADER,
} from '../actions/actions';

const initialState: MessageNotification = {
	showMessage: false,
	message: '',
	type: '',
	duration: 3000,
	type1: 'error',
	type2: 'error',
	showMultiMessage: false,
	messagearray: [],
	madcaploader: false,
};
const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case SHOW_MULTI_MESSAGE:
			return updateObject(state, {
				messagearray: action.payload?.msgarray,
				type1: action.payload?.type1,
				type2: action.payload?.type2,
				showMessage: false,
				showMultiMessage: true,
				duration: action.payload?.duration,
				madcaploader: true,
			});
		case START_MADCAP_LOADER:
			return updateObject(state, {
				messagearray: action.payload?.msgarray,
				type1: action.payload?.type1,
				type2: action.payload?.type2,
				showMessage: false,
				showMultiMessage: true,
				duration: action.payload?.duration,
				madcaploader: true,
			});
		case STOP_MADCAP_LOADER:
			return updateObject(state, {
				messagearray: action.payload?.msgarray,
				type1: action.payload?.type1,
				type2: action.payload?.type2,
				showMessage: false,
				showMultiMessage: true,
				duration: action.payload?.duration,
				madcaploader: false,
			});
		case SHOW_MESSAGE:
			return updateObject(state, {
				message: action.payload?.msg,
				type: action.payload?.type,
				showMessage: true,
				showMultiMessage: false,
				duration: action.payload?.duration,
			});
		case HIDE_MESSAGE:
			return updateObject(state, {
				message: '',
				type: '',
				showMessage: false,
				showMultiMessage: false,
				duration: 3000,
				type1: 'error',
				type2: 'error',
				messagearray: [],
			});
		default:
			return state;
	}
};

export default reducer;
