import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import * as localForage from 'localforage';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createEncryptor from 'redux-persist-transform-encrypt';
import thunk from 'redux-thunk';
import { rehydrateStore } from './actions/rehydrate';
import {
	authReducer,
	coaReducer,
	drpErrorReducer,
	drpReducer,
	leftMenuReducer,
	madcapintegrationReducer,
	marketPriceReducer,
	messageReducer,
	pdfprintdataReducer,
	producerReducer,
	rehydrateReducer,
	syncOfflineReducer,
	yearReducer,
} from './reducers';
import multiYearReportReducer from './reducers/multiYearPrintReducer';
declare var window: any;

const effect = (effect: any, _action: any) => {
	return effect;
};
const discard = (error: any, _action: any, _retries: any) => {
	const { request, response } = error;
	if (!request) throw error; // There was an error creating the request
	if (!response) return false; // There was no response
	return 400 <= response.status && response.status < 500;
};

const encryptor = createEncryptor({
	secretKey: 'producer-profitability',
});

const persistOptions = {
	key: 'pp',
	storage: localForage,
	blacklist: ['rehydrate', 'coa', 'producer', 'marketprice', 'message'],
	transforms: [encryptor],
};

const config = {
	...offlineConfig,
	effect,
	persistOptions,
	persistCallback: () => {
		store.dispatch(rehydrateStore());
	},
};
const rootReducer = combineReducers({
	auth: authReducer,
	producer: producerReducer,
	synced: syncOfflineReducer,
	rehydrate: rehydrateReducer,
	coa: coaReducer,
	marketprice: marketPriceReducer,
	message: messageReducer,
	leftMenu: leftMenuReducer,
	pdfdata: pdfprintdataReducer,
	selectedYear: yearReducer,
	madcap: madcapintegrationReducer,
	multiYearPrint: multiYearReportReducer,
	drpReducer,
	drpErrorReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk), offline(config))
);

export { store };
