import { createTheme } from '@material-ui/core/styles';
import React from 'react';

import {
	MuiThemeProvider,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	StylesProvider,
	Theme,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { CustomToolTip as Tooltip } from '..';
import { GlobalState, IStep, ISubStep } from './../../../../schemas';
import './../../../../styles/components/step-wizard.scss';

const theme: Theme = createTheme({
	typography: {
		fontFamily: `"URWDINCond-Black"`,
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		body2: {
			fontSize: 14,
		},
	},
	overrides: {
		MuiStepper: {
			root: {
				padding: '20px 20px 15px',
				'@media screen and (max-width:1050px)': {
					padding: '20px 15px 5px',
				},
			},
		},
		MuiStep: {
			root: {
				cursor: 'pointer',
			},
			completed: {
				position: 'relative',
				'&:first-child::before': {
					content: "'1'",
				},
				'&:nth-child(3)::before': {
					content: "'2'",
				},
				'&:nth-child(5)::before': {
					content: "'3'",
				},
				'&:nth-child(7)::before': {
					content: "'4'",
				},
				'&:nth-child(9)::before': {
					content: "'5'",
				},
				'&:nth-child(11)::before': {
					content: "'6'",
				},
				'&::before': {
					position: 'absolute',
					color: 'white',
					width: '27px',
					height: '27px',
					background: '#00B1AD',
					borderRadius: '50%',
					padding: '4px 9px',
					fontWeight: 'bold',
					fontSize: '18px',
					boxSizing: 'border-box',
				},
			},
		},

		MuiStepLabel: {
			root: {
				textTransform: 'uppercase',
			},
			active: {
				color: '#204f56 !important',
				fontSize: '16px',
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
			},
			completed: {
				color: '#204f56 !important',
				fontSize: '16px',
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
			},
		},
		MuiStepContent: {
			root: {
				marginTop: '0',
			},
		},

		MuiStepConnector: {
			line: {
				borderColor: '#808078',
			},
			vertical: {
				padding: '0',
			},
			lineVertical: {
				borderLeftWidth: '1.5px',
			},
			active: {
				position: 'relative',
				'&::after': {
					content: "''",
					height: '24px',
					position: 'absolute',
					top: '0',
					borderLeft: '1.5px solid #00B1AD',
				},
			},
			completed: {
				position: 'relative',

				'&::after': {
					content: "''",
					height: '35px',
					position: 'absolute',
					top: '0',
					borderLeft: '1.5px solid #00B1AD',
				},
			},
		},
		MuiStepIcon: {
			root: {
				fill: '#808078',
			},
			active: {
				fill: '#530fdd',
			},
			completed: {
				opacity: '0',
			},
		},
	},
});

interface Iprop extends RouteComponentProps {
	activeStep: number;
	activeSubStep: number;
	steps: Array<IStep>;
	id: string;
	completeClicked(): void;
	saveClicked(): void;
	stepClicked(clickedIndex: number): number;
	subStepClicked(
		steps: Array<IStep>,
		step: IStep,
		substep: ISubStep,
		index: number
	): ISubStep;
	nextClicked(step: number, id: string): IStep;
	saveAndClose(): void;
	handleClose(): void;
	reset(): void;
	print(): void;
	updateMts(): void;
	disabled?: boolean;
	printMsg?: string;
	isComponentInitialized?: boolean;
}
type Props = Iprop & any;
function StepWizard(props: Props) {
	var currentDate: any;
	var today = new Date();
	currentDate =
		today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

	const { activeStep, id, steps, activeSubStep, disabled } = props;

	const handleNext = () => {
		props.nextClicked(activeStep, id);
	};
	const handleSave = () => {
		props.saveClicked();
	};

	const handleClose = () => {
		props.handleClose();
	};

	const handleComplete = () => {
		props.completeClicked();
	};
	const handleStep = (clickedStep: number, id: string) => () => {
		props.stepClicked(clickedStep, id);
	};
	const updateDate = () => {
		if (!props?.disabled) {
			props.updateMts();
		}
	};
	const handleSaveAndClose = () => {
		props.saveAndClose();
	};

	const handleReset = () => {
		props.reset();
	};
	const printPdf = () => {
		props.print();
	};
	const subStepClicked = (
		steps: Array<IStep>,
		step: IStep,
		substep: ISubStep,
		index: number
	) => {
		props.subStepClicked(steps, step, substep, index);
	};

	// get market price date
	const MTM = useSelector((state: GlobalState) => {
		const mtmDate =
			state.marketprice.list?.length > 0
				? new Date(state.marketprice.list[0].tradingDateTime)
				: null;

		const date = mtmDate
			? String(mtmDate.getMonth() + 1).padStart(2, '0') +
			  '/' +
			  String(mtmDate.getDate()).padStart(2, '0') +
			  '/' +
			  mtmDate.getFullYear()
			: null;

		return {
			date: date,
			isLoading: state.marketprice.loading,
		};
	});

	return (
		<MuiThemeProvider theme={theme}>
			<StylesProvider>
				<Stepper
					data-testid="stepWizard"
					activeStep={activeStep}
					orientation="vertical"
				>
					{steps.map((step: IStep, index: number) => (
						<Step onClick={handleStep(index, step.id)} key={index}>
							<StepLabel>{step.name}</StepLabel>
							<StepContent>
								<Step className="substepper">
									{step.substeps.map((substep: ISubStep, index: number) => {
										return (
											<StepLabel
												onClick={event => {
													subStepClicked(steps, step, substep, index);
													event.stopPropagation();
												}}
												key={index}
												className={`${
													activeSubStep === index
														? 'active'
														: substep.completed
														? 'completed'
														: ''
												}`}
											>
												{substep.name}
											</StepLabel>
										);
									})}
								</Step>
							</StepContent>
						</Step>
					))}
				</Stepper>

				<div className="btnWrapper">
					{activeStep !== steps.length && (
						<button
							className={cx('orangebtn', {
								disabledbtn: disabled || activeStep == steps.length - 1,
							})}
							disabled={disabled}
							onClick={handleNext}
							data-automate-id="Next Step Button"
						>
							Next Step
						</button>
					)}
					{activeStep === steps.length && (
						<button
							className={cx('orangebtn', { disabledbtn: disabled })}
							onClick={handleComplete}
							disabled={disabled}
							data-automate-id="Submit Button"
						>
							Submit
						</button>
					)}

					<button
						className={cx('greenbtn', { disabledbtn: disabled })}
						onClick={handleSaveAndClose}
						disabled={disabled}
						data-automate-id="Save and Close Button"
					>
						SAVE AND CLOSE
					</button>
					{/* <button
						className={cx('orangebtn', { disabledbtn: disabled })}
						onClick={handleClose}
						data-automate-id="Close Button"
					>
						Close
					</button> */}
					<div className="d_flex">
						<button
							className={cx('greenbtn', { disabledbtn: disabled })}
							onClick={handleReset}
							disabled={disabled}
							data-automate-id="Reset Button"
						>
							RESET
						</button>
						{!props?.printMsg && (
							<button
								className={cx('greenbtn', { disabledbtn: disabled })}
								onClick={printPdf}
								disabled={disabled}
								data-automate-id="Print Button"
							>
								PRINT
							</button>
						)}
					</div>
					{props?.printMsg && (
						<div className="printmsg">{props?.printMsg || ''}</div>
					)}
				</div>
				{props?.update && (
					<Tooltip title="Mark to Market">
						<div className={cx('greenbtnWrapper', 'd_flex')}>
							{!MTM?.isLoading && MTM?.date ? (
								<>
									<span className="mts_date">MTM DATE: {MTM.date}</span>
									<span
										className="refreshicon"
										data-automate-id="Refresh MTM"
										onClick={updateDate}
									>
										<AutorenewIcon className="iconbox"></AutorenewIcon>
									</span>
								</>
							) : (
								<>
									<span className="refreshtext">Refreshing...</span>
									<span className="refreshicon">
										<HourglassFullIcon className="iconbox"></HourglassFullIcon>
									</span>
								</>
							)}
						</div>
					</Tooltip>
				)}
			</StylesProvider>
		</MuiThemeProvider>
	);
}
export default StepWizard;
