const DRP_COMP_ENDORSEMENT = {
	Endorsement_Date: 'Endorsement Date',
	Declared_Production_QTR: 'Declared Production/QTR (Lbs)',

	QMP_Butterfat: 'Quarterly Market Price - Butterfat ($/cwt)',
	QMP_Protein: 'Quarterly Market Price - Protein ($/cwt)',
	QMP_Other_Solids: 'Quarterly Market Price - Other Solids ($/cwt)',
	QMP_Non_Fat_Solids: 'Quarterly Market Price - Non-Fat Solids ($/lbs)',

	DT_Butterfat: 'Declared Test - Butterfat',
	DT_Protein: 'Declared Test - Protein',
	DT_Other_Solids: 'Declared Test - Other Solids',
	DT_Non_Fat_Solids: 'Declared Test - Non-Fat Solids',

	QT_Butterfat: 'Quote - Butterfat ($/cwt)',
	QT_Protein: 'Quote - Protein ($/cwt)',
	QT_Other_Solids: 'Quote - Other Solids ($/cwt)',
	QT_Non_Fat_Solids: 'Quote - Non-Fat Solids ($/lb)',

	Comp_Weight_factor: 'Component Weighting Factor',
	NonFt_BF_Wght: 'Nonfat/Butterfat Weighting Factor',
	Protection_Factor: 'Protection Factor/QTR (number)',
	Percentage_Covered: 'Percent of Milk Covered (%)',
	Yield_Adj_Fact: 'Yield Adjustment Factor Ratio (YF)',
	Premium_Paid_cwt: 'Premium Paid ($/cwt)',
	Premium_Paid_Total: 'Premium Dollar',
	Drp_Reference: 'DRP Reference',
	Drp_Note: 'DRP Note',
};

const DRP_COMP_ENDORSEMENT_SUMMARY = {
	Total_Price_CWT: 'Total Price ($/cwt)',

	Gross_Expected_CWT: 'Expected Revenue Guarantee ($/cwt)',
	Gross_Expected_TOTAL: 'Expected Revenue Guarantee (Total$)',

	Beg_CWT: 'Beginning Profitable Level ($/cwt)',
	Beg_TOTAL: 'Beginning Profitable Level (Total$)',

	Market_Price_CWT: 'Market Price Weighted ($/cwt)',
	Market_Price_TOTAL: 'Market Price Weighted (Total$)',

	Gross_Prob_CWT: 'Gross Probable Indemnity ($/cwt)',
	Gross_Prob_TOTAL: 'Gross Probable Indemnity (Total$)',

	MTM_Adj_CWT: 'MTM w/YF Adjustment ($/cwt)',
	MTM_Adj_TOTAL: 'MTM w/YF Adjustment (Total$)',

	MTM_CWT: 'Market to Market ($/cwt)',
	MTM_TOTAL: 'Market to Market (Total$)',

	Net_MTM_TOTAL: 'Net MTM Total',
	Net_MTM_CWT: 'Net MTM CWT',
};

const DRP_COMP_QUARTERLY_SUMMARY = {
	TOTAL_COVERED_PRODUCTION_MONTHLY: 'Total Covered Production/Mo',
	TOTAL_COVERED_PRODUCTION_QTR: 'Total Covered Production/QTR',
	WEIGHT_AVG_YIELD: 'Weight Average Yield Adjustment Factor',
	WEIGHT_AVG_PROTECTION_FACTOR: 'Weight Average Protection Factor',

	MARKET_PRICE_WEIGHTED_CWT: 'Market Price Weighted ($/cwt)',
	MARKET_PRICE_WEIGHTED_QTR: 'Market Price Weighted/QTR (Total$)',
	MARKET_PRICE_WEIGHTED_MONTHLY: 'Market Price Weighted/Mo (Total$)',

	GROSS_EXPECTED_RG_CWT: 'Gross Expected Revenue Guarantee ($/cwt)',
	GROSS_EXPECTED_RG_QTR: 'Gross Expected Revenue Guarantee/QTR (Total$)',
	GROSS_EXPECTED_RG_MONTH: 'Gross Expected Revenue Guarantee/Mo (Total$)',

	BEG_CWT: 'Beginning Profitable Level ($/cwt)',
	BEG_QTR: 'Beginning Profitable Level/QTR (Total$)',
	BEG_MONTH: 'Beginning Profitable Level/Mo (Total$)',

	GPI_QTR: 'Gross Probable Indemnity/QTR (Total$)',
	GPI_CWT: 'Gross Probable Indemnity ($/cwt)',
	GPI_MONTH: 'Gross Probable Indemnity/Mo (Total$)',

	MTM_ADJ_QTR: 'MTM w/YF Adjustment/QTR (Total$)',
	MTM_ADJ_CWT: 'MTM w/YF Adjustment ($/cwt)',
	MTM_ADJ_MONTH: 'MTM w/YF Adjustment/Mo (Total$)',

	MTM_CWT: 'Market to Market ($/cwt)',
	MTM_QTR: 'Market to Market/QTR (Total$)',
	MTM_MONTH: 'Market to Market/Mo (Total$)',

	NET_MTM_CWT: 'NET MTM CWT',
	NET_MTM_MONTH: 'NET MTM MONTH',
	NET_MTM_QTR: 'NET MTM QTR',
};

export {
	DRP_COMP_ENDORSEMENT,
	DRP_COMP_ENDORSEMENT_SUMMARY,
	DRP_COMP_QUARTERLY_SUMMARY,
};
