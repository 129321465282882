import { ActionCreator, Dispatch } from 'redux';
import { DRP_API, MADCAP_API } from '../../common/configs';
import { http } from '../../common/services';
import {
	MAD_CAP_API_RESET,
	MAD_CAP_API_SUCCESS,
	MAD_CAP_FETCH_START,
	RM_POSITION_DATA_FROM_MADCAP,
	RM_POSITION_HEADER_UPDATE,
} from './actions';
import { coaDataChanged } from './coa';
import { showMultiMessage } from './message-notification';

interface IMadCapResult {
	type: string;
}

const fetchStart: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_FETCH_START,
	};
};

const fetchreset: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_API_RESET,
	};
};

const fetchSuccess: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_API_SUCCESS,
	};
};

const updateRMRecordWithMadCapPositionandDRPExternalSource = (payload: any) => {
	return {
		type: RM_POSITION_DATA_FROM_MADCAP,
		payload: payload,
	};
};

const updateHeaderInformationWithDate = () => {
	return {
		type: RM_POSITION_HEADER_UPDATE,
	};
};
export const DRPNOTFOUNDMSG = 'No DRP data available for given period';
export const MADCAPNOTFOUNDMSG = 'No MadCap data available for given period';
export const MADCAPISSUEERRORMSG =
	'An issue was encountered while accessing MadCap data. Please try again later. If the issue persists, please contact support.';
const getmadcappositionsdata = (
	farmID: string = '55004',
	startDate: string = '1',
	endDate: string = '1',
	year: number
) => {
	return (dispatch: Dispatch) => {
		dispatch(fetchStart());
		const apiRequest = [];
		apiRequest.push(
			http.get(MADCAP_API.GETMADCAPDATA(farmID, startDate, endDate))
		);
		apiRequest.push(http.get(DRP_API.GETDRPDATA(farmID, startDate, endDate)));
		Promise.allSettled(apiRequest).then(results => {
			let madcapmessage = '',
				drpmessage = '';

			let madcapstatus: 'success' | 'error' | 'warning' = 'success';
			let drpstatus: 'success' | 'error' | 'warning' = 'success';
			let madcapdata = null,
				drpdata = null;
			let calldrp = false;
			let callmadcapposition = false;
			console.log('RESULT ===>', results);
			if (results[0].status === 'fulfilled') {
				//madcap pull success
				madcapmessage = 'MadCap: Data Update Complete';
				madcapdata = results[0].value.data;
				if (madcapdata['get-milkContract'].length === 0) {
					madcapmessage = 'MadCap: No data available for given period';
					madcapstatus = 'warning';
					dispatch(fetchreset());
				} else {
					callmadcapposition = true;
				}
			}
			if (results[1].status === 'fulfilled') {
				//drp pull success
				drpmessage = 'DRP: Data Update Complete';
				drpdata = results[1].value.data;
				if (drpdata === '') {
					drpmessage = 'DRP: No data available for given period';
					drpstatus = 'warning';
				} else {
					calldrp = true;
				}
			}

			if (results[0].status === 'rejected') {
				//madcap pull Error
				madcapmessage =
					'MadCap: Error in integration. Please try again later. If persists, please contact support';
				madcapstatus = 'error';
				dispatch(fetchreset());
			}

			if (results[1].status === 'rejected') {
				//drp pull Error
				drpmessage =
					'DRP: Error in integration. Please try again later. If persists, please contact support';
				drpstatus = 'error';
			}

			//call madcapdispatch
			if (callmadcapposition || calldrp) {
				dispatch(coaDataChanged(true));
				dispatch(updateHeaderInformationWithDate());
				dispatch(
					updateRMRecordWithMadCapPositionandDRPExternalSource({
						listContracts: callmadcapposition
							? madcapdata['get-milkContract']
							: [],
						externaldrpdata: calldrp ? drpdata : [],
						financialYear: year,
					})
				);
			}
			dispatch(fetchSuccess());

			dispatch(
				showMultiMessage([madcapmessage, drpmessage], madcapstatus, drpstatus)
			);
		});
	};
};

export {
	fetchreset,
	fetchStart,
	fetchSuccess,
	getmadcappositionsdata,
	updateHeaderInformationWithDate,
};
