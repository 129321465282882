import { Box, Snackbar, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideMessage } from '../../../store/actions';
import '../../../styles/layout/mixed-chart.scss';

interface IProp {
	msg?: string;
	type?: any;
	messagearray?: string[] | undefined;
	type1?: 'success' | 'error' | 'warning' | undefined;
	type2?: 'success' | 'error' | 'warning' | undefined;
	duration?: number | null;
}
type Props = IProp;
function Message(props: Props) {
	// const { msg, type, duration } = props;
	const dispatch = useDispatch();

	const getIcon = (type: 'success' | 'warning' | 'error' | undefined) => {
		switch (type) {
			case 'success':
				return (
					<CheckCircleIcon
						style={{
							color: [props.type1, props.type2].includes('error')
								? '#ffffff'
								: 'yellowgreen',
						}}
					/>
				);
			case 'warning':
				return (
					<WarningIcon
						style={{
							color: [props.type1, props.type2].includes('error')
								? '#ffffff'
								: '#ffcc00',
						}}
					/>
				);
			case 'error':
				return (
					<ErrorIcon
						style={{
							color: [props.type1, props.type2].includes('error')
								? '#ffffff'
								: 'red',
						}}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{props.messagearray && props.type1 && props.type1 ? (
				<Snackbar
					style={{
						zIndex: '20000',
					}}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					onClose={() => {
						dispatch(hideMessage());
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '30vw',
							gap: 2,
							padding: 10,
							borderRadius: 10,
							backgroundColor: [props.type1, props.type2].includes('error')
								? 'red'
								: '#ffffff', // White background for the entire box
							color: [props.type1, props.type2].includes('error')
								? '#ffffff'
								: 'black',
							border: '2px solid green', // Fixed green border for the whole box
							boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
						}}
					>
						{props.messagearray.map((message, index) => (
							<Box
								key={index}
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 1,
									mt: index > 0 ? '1rem' : '0',
								}}
							>
								{getIcon(index === 0 ? props.type1 : props.type2)}
								<Typography
									sx={{
										color: 'black', // Black text color
										fontSize: '1rem',
									}}
								>
									{message}
								</Typography>
							</Box>
						))}
					</Box>
				</Snackbar>
			) : (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					autoHideDuration={props?.duration}
					onClose={() => {
						dispatch(hideMessage());
					}}
				>
					<MuiAlert elevation={6} variant="filled" severity={props.type}>
						{props.msg}
					</MuiAlert>
				</Snackbar>
			)}
		</>
	);
}
export default Message;
