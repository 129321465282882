import {
	API_AGENCY_DRP,
	API_BASE_PATH,
	API_PARTIAL_AUTH,
	API_PARTIAL_COP,
	API_PARTIAL_RM,
	API_PARTIAL_WEB,
	SCHEMA_TYPE,
} from '../constants/constants';

const AUTH_API = {
	AUTHORIZATION: (code: string): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_AUTH()}/userAuthorization/login?code=${code}`;
	},
	LOGOUT: (): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_AUTH()}/logout`;
	},
};

const PRODUCER_API = {
	PRODUCERS: `${API_BASE_PATH()}${API_PARTIAL_WEB()}/partyMaster`,
	PRODUCER_BY_ID: (id: string): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_WEB()}/partyMaster/${id}`;
	},
	VERSION_UPDATE: (partyIdentifier: string): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_WEB()}/journalEntry/${partyIdentifier}/updateStatus`;
	},
};

const RM_API = {
	MARKET_PRICE_LIST: (date: string = '', symbol: string = ''): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_WEB()}/marketPricing/?tradingDate=${date}&symbolCode=${symbol}`;
	},
	MARKET_PRICE_HISTORIC_LIST: (
		date: string = '',
		month: string = '',
		year: string = '',
		fetchNonLatest = false
	): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_WEB()}/marketPricing/?tradingDate=${date}&month=${month}&year=${year}&fetchNonLatest=${fetchNonLatest}`;
	},

	MARKET_PRICE_POST: () =>
		`${API_BASE_PATH()}${API_PARTIAL_WEB()}/marketPricing`,
};
const COA_API = {
	GET_COP_SCHEMA: (identifer: string = ''): string => {
		return `${API_BASE_PATH()}${API_PARTIAL_WEB()}/chartOfAccounts/?versionIdentifier=${identifer}`;
	},
	COP_SAVE: (partyIdentifer: string, type: string = ''): string => {
		if (type.toLowerCase() == 'rm') {
			return `${API_BASE_PATH()}${API_PARTIAL_RM()}/journalEntry/${partyIdentifer}?type=${type}`;
		} else {
			return `${API_BASE_PATH()}${API_PARTIAL_COP()}/cop/${partyIdentifer}?type=${type}`;
		}
	},
	GET_COP_DATA: (
		partyIdentifer: string,
		year: string,
		revisionIdentifier: string,
		type: string
	): string => {
		if (type.toLowerCase() == 'rm') {
			return `${API_BASE_PATH()}${API_PARTIAL_RM()}/journalEntry/${partyIdentifer}?year=${year}&revisionIdentifier=${revisionIdentifier}&type=${type}`;
		} else {
			return `${API_BASE_PATH()}${API_PARTIAL_COP()}/journalEntry/${partyIdentifer}?year=${year}&revisionIdentifier=${revisionIdentifier}&type=${type}`;
		}
	},
	GET_ACCOUNTING_YEARS: (partyIdentifer: string, type: string) => {
		return `${API_BASE_PATH()}${API_PARTIAL_COP()}/copyears/${partyIdentifer}`;
	},
	GET_JOURNAL_ENTRY_LINES: (
		partyIdentifer: string,
		identifiers: string,
		years: string,
		type: string
	) => {
		if (type === SCHEMA_TYPE.RM) {
			return `${API_BASE_PATH()}${API_PARTIAL_RM()}/journalEntry/${partyIdentifer}/journalEntryLines?type=${type}&typeCodes=${identifiers}&years=${years}`;
		} else {
			return `${API_BASE_PATH()}${API_PARTIAL_COP()}/cop/${partyIdentifer}/journalEntryLines?type=${type}&lineIdentifiers=${identifiers}&years=${years}`;
		}
	},
};

const MADCAP_API = {
	GETMADCAPDATA: (farmid: string, startdate: string, enddate: string) => {
		return `${API_BASE_PATH()}/security/v1/userAuth/madcap/api/GetMadCapPositions?farmerID=${farmid}&startDate=${startdate}&endDate=${enddate}`;
	},
};
const DRP_API = {
	GETDRPDATA: (farmid: string, startdate: string, enddate: string) => {
		return `${API_BASE_PATH()}/finance/v1/drpapi/api/getendorsementdetails?farmID=${farmid}&startDate=${startdate}&endDate=${enddate}`;
	},
};
const GET_POLICY_API = {
	GETPOLICYKEY: (policyNumber: string, agencyCode: string, farmID: string) => {
		return `${API_BASE_PATH()}/finance/v1/drpapi/api/getpolicykey?policyNumber=${policyNumber}&aip=${agencyCode}&farmId=${farmID}`;
	},
};
const GET_POLICY_RECORDS_API = {
	GETPOLICYRECORDS: (farmId: string) => {
		return `${API_BASE_PATH()}/finance/v1/drpapi/api/getexistingmapping?farmId=${farmId}`;
	},
};
const GET_AGENCIES = `${API_BASE_PATH()}${API_AGENCY_DRP()}`;

const POST_POLICY_RECORDS_API = {
	POSTPOLICYRECORDS: () => {
		return `${API_BASE_PATH()}/finance/v1/drpapi/api/savefarmpolicymappingrecords`;
	},
};
export {
	AUTH_API,
	COA_API,
	DRP_API,
	GET_AGENCIES,
	GET_POLICY_API,
	GET_POLICY_RECORDS_API,
	MADCAP_API,
	POST_POLICY_RECORDS_API,
	PRODUCER_API,
	RM_API,
};
