import {
	HIDE_MESSAGE,
	SHOW_MESSAGE,
	SHOW_MULTI_MESSAGE,
	START_MADCAP_LOADER,
	STOP_MADCAP_LOADER,
} from './actions';

import { ActionCreator } from 'redux';
import { GenericSchema } from '../../schemas';

interface IShowMessageAction {
	type: string;
	payload: GenericSchema;
}
interface IHideMessageAction {
	type: string;
}

const hideMessage: ActionCreator<IHideMessageAction> = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

const startMadcaploader: ActionCreator<IHideMessageAction> = () => {
	return {
		type: START_MADCAP_LOADER,
	};
};

const stopMadcaploader: ActionCreator<IHideMessageAction> = () => {
	return {
		type: STOP_MADCAP_LOADER,
	};
};

const showMessage: ActionCreator<IShowMessageAction> = (
	msg: string,
	type: string = 'error',
	duration: number | null = 3000
) => {
	return {
		type: SHOW_MESSAGE,
		payload: { msg, type, duration },
	};
};

const showMultiMessage: ActionCreator<IShowMessageAction> = (
	msgarray: string[],
	type1: string = 'error',
	type2: string = 'error',
	duration: number | null = 3000
) => {
	return {
		type: SHOW_MULTI_MESSAGE,
		payload: { msgarray, type1, type2, duration },
	};
};

export {
	hideMessage,
	showMessage,
	showMultiMessage,
	startMadcaploader,
	stopMadcaploader,
};
