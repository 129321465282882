import React, { FC } from 'react';
import classes from '../../styles/layout/page-header.module.scss';

interface Props {
	children?: React.ReactNode;
}

const PageHeader: FC<Props> = (props: Props) => {
	return (
		<>
			<div className={classes.headercontainer}>{props.children}</div>
		</>
	);
};

export default PageHeader;
