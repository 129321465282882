import { SET_DRP_ERROR } from '../actions/actions';

// Define the shape of the state
interface DrpErrorState {
	drpError: boolean;
	// Add other state properties if needed
}

// Initial state
const initialState: DrpErrorState = {
	drpError: false,
	// other state properties...
};

// Reducer function
const drpErrorReducer = (
	state: DrpErrorState = initialState,
	action: any
): DrpErrorState => {
	switch (action.type) {
		case SET_DRP_ERROR:
			return {
				...state,
				drpError: action.payload,
			};
		default:
			return state;
	}
};

export default drpErrorReducer;
